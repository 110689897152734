 /*local */

 
// export const apiUrl      = "http://localhost/petcare/api/";
// export const baseUrl      = "http://localhost/petcare";
// export const mediaUrl      = "http://localhost/petcare/media/";
// export const carImageUrl      = "http://localhost/petcare/media/cars";
// export const sampleFileUrl      = "http://localhost/petcare/media/sample_file/";
// export const licenseURL       = "http://localhost/petcare/media/driverlicense";
// export const insuranceURL       = "http://localhost/petcare/media/driverinsurance";
// export const profileURL       = "http://localhost/petcare/media/driverprofile";
// export const drivercarURL       = "http://localhost/petcare/media/drivercarimage";
// export const cmscontentURL       = "http://localhost/petcare/media/cmscontent";
// export const categoryURL       = "http://localhost/petcare/media/category";
// export const specialistURL       = "http://localhost/petcare/media/specialists";
// export const productURL       = "http://localhost/petcare/media/product";
// export const formentriesURL       = "http://localhost/petcare/media/formentries";
// export const invoiceURL       = "http://localhost/petcare/media/order_invoice";
// export const doctorURL       = "http://localhost/petcare/media/doctor"; 
// export const technicianURL       = "http://localhost/petcare/media/technician"; 
// export const guideURL       = "http://localhost/petcare/media/guide"; 


/*Live */

export const apiUrl      = "https://runadmin.uxt.design/petcare/api/";
export const baseUrl      = "https://runadmin.uxt.design/petcare";
export const mediaUrl      = "https://runadmin.uxt.design/petcare/media/";
export const carImageUrl      = "https://runadmin.uxt.design/petcare/media/cars";
export const sampleFileUrl      = "https://runadmin.uxt.design/petcare/media/sample_file/";
export const licenseURL       = "https://runadmin.uxt.design/petcare/media/driverlicense";
export const insuranceURL       = "https://runadmin.uxt.design/petcare/media/driverinsurance";
export const profileURL       = "https://runadmin.uxt.design/petcare/media/driverprofile";
export const drivercarURL       = "https://runadmin.uxt.design/petcare/media/drivercarimage";
export const cmscontentURL       = "https://runadmin.uxt.design/petcare/media/cmscontent";
export const categoryURL       = "https://runadmin.uxt.design/petcare/media/category";
export const specialistURL       = "https://runadmin.uxt.design/petcare/media/specialists";
export const productURL       = "https://runadmin.uxt.design/petcare/media/product";
export const formentriesURL       = "https://runadmin.uxt.design/petcare/media/formentries";
export const invoiceURL       = "https://runadmin.uxt.design/petcare/media/order_invoice";
export const doctorURL       = "https://runadmin.uxt.design/petcare/media/doctor"; 
export const technicianURL       = "https://runadmin.uxt.design/petcare/media/technician"; 
export const guideURL       = "https://runadmin.uxt.design/petcare/media/guide/"; 
export const template1       = "https://runadmin.uxt.design/petcare/media/template1.png"; 
export const template2       = "https://runadmin.uxt.design/petcare/media/template2.png"; 
export const template3       = "https://runadmin.uxt.design/petcare/media/template3.png"; 






