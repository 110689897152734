import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import {
    Row,
    Col,
    Modal,
    Table,
    OverlayTrigger,
    ButtonToolbar,
    Tooltip,
    Button
  } from "react-bootstrap"; 
  
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
// import noimage from '../public/no-image.png';

class DoctorAssign extends Component {

  constructor(props)
  {
    super(props); 
    const orderid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
    this.state = {      
        orderid: orderid,
      productorderlistdata:'',
      productdetails:'',
      doctorlist:'',
      scheduledate:'',
      selecteddoctor:[],
      doctorassignlist:'',
      scheduleHours:'',
			selhoursvalues:'',
			scheduleMinutes:'',
			selminvalues:'',
			scheduleMeridiem:'',
			selmerivalues:'',
			assignsno:'',
			assignorderid:'',
    };
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
   
    var postObject = {             
        id   :this.state.orderid,
        admin_id: admin_id,
        loggedas:loggedas
    };
    axios.post(apiUrl+"ProductBookingHistory/getdoctor_visit",qs.stringify(postObject)).then(res => {
       if(res.data.status === 'success'){

          this.setState({ productorderlistdata: res.data.productview[0],productdetails:res.data.products,doctorlist: res.data.specialistdoctors});
      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Doctor Allocate');
     var qs = require('qs');
     var postObject1 = {             
          order_id   :this.state.orderid,
          admin_id: localStorage.getItem("admin_id")
      };
     axios.post(apiUrl+"ProductBookingHistory/getdoctorVisitrecords",qs.stringify(postObject1)).then(res => {
      if(res.data.status === 'success'){
        this.setState({doctorassignlist:res.data.booking_result});
     }
   }); 

   }
   productorderdetails()
   {
    var productdetails = this.state.productdetails[0];
    if (productdetails != "undefined" && productdetails != null) {
              let sno =1;
              if(productdetails.no_of_visite!==null)
              {
                  var i=1;
                  // var j=0;
                  var data =[];
                  var doctorlist = this.state.doctorlist;
                  var doctorassignlist = this.state.doctorassignlist;

                  const output=[];

                  for(i;i<=productdetails.no_of_visite;i++)
                  {
                    // for(j;j<=doctorassignlist.length;j++)
                    // {
                    //   output.push(doctorassignlist[j]);
                    // }


                    // console.log(output,'output')
                      data[i] =  this.doctor_allocate(i,doctorassignlist[i-1]);
                    

                  }
                  return (
                    <>
                    <div>{data}</div>
                      
                    </>
                  );
                  }
              }             
        }
   handleClickSelecetDoctor = (event) => {
    const {name, value} = event.target; 
    console.log(value,'value');
      this.setState({
          [name]: value
        });
  }
  handleChangeDate(sno,date) {	
    if(sno==1){
      this.setState({     
        scheduledate_1: moment(date).format('YYYY-MM-DD'),
        selecteddate_1:date
      });
    }else if(sno==2){
      this.setState({     
        scheduledate_2: moment(date).format('YYYY-MM-DD'),
        selecteddate_2:date
      });
    }else if(sno==3){
      this.setState({     
        scheduledate_3: moment(date).format('YYYY-MM-DD'),
        selecteddate_3:date
      });
    }
    else if(sno==4){
      this.setState({     
        scheduledate_4: moment(date).format('YYYY-MM-DD'),
        selecteddate_4:date
      });
    }

  }
  handleChangeHr(sno,selectedHours){
   
    if(sno==1){
      this.setState({selectedHours_1:selectedHours})
      this.setState({  selhoursvalues_1 : selectedHours.value});
    }else if(sno==2){
      this.setState({selectedHours_2:selectedHours})
      this.setState({  selhoursvalues_2 : selectedHours.value});
    }else if(sno==3){
      this.setState({selectedHours_3:selectedHours})
      this.setState({  selhoursvalues_3 : selectedHours.value});
    }
    else if(sno==4){
      this.setState({selectedHours_4:selectedHours})
      this.setState({  selhoursvalues_4 : selectedHours.value});
    }
    $('.hours').removeClass('errorhours');
  };
  handleChangeMinutes(sno,selectedMinutes){

    if(sno==1){
      this.setState({selectedMinutes_1:selectedMinutes})
      this.setState({selminvalues_1 : selectedMinutes.value});
    }else if(sno==2){
      this.setState({selectedMinutes_2:selectedMinutes})
      this.setState({selminvalues_2 : selectedMinutes.value});
    }else if(sno==3){
      this.setState({selectedMinutes_3:selectedMinutes})
      this.setState({selminvalues_3 : selectedMinutes.value});
    }
    else if(sno==4){
      this.setState({selectedMinutes_4:selectedMinutes})
      this.setState({selminvalues_4 : selectedMinutes.value});
    }    
    $('.minutes').removeClass('errormins');
  };
   handleChangeMeridiem(sno,selectedMeridiem){
    if(sno==1){
      this.setState({selectedMeridiem_1:selectedMeridiem})
      this.setState({selmerivalues_1 : selectedMeridiem.value});
    }else if(sno==2){
      this.setState({selectedMeridiem_2:selectedMeridiem})
      this.setState({selmerivalues_2 : selectedMeridiem.value});
    }else if(sno==3){
      this.setState({selectedMeridiem_3:selectedMeridiem})
      this.setState({selmerivalues_3 : selectedMeridiem.value});
    }
    else if(sno==4){
      this.setState({selectedMeridiem_4:selectedMeridiem})
      this.setState({selmerivalues_4 : selectedMeridiem.value});
    }    
   };
  handleFormSubmit = (e,sno,order_id) => {
    if(this.validateForm(sno)){
    
    this.cancelConfirmPopup.bind(this, sno,order_id);


        
    }
}
assignFormSubmit(sno,order_id){
  
  var admin_id = localStorage.getItem("admin_id");
  const formPayload = this.state;
  if(sno==1)
  {
    var postObject = {
      admin_id       : admin_id,      
      selecteddoctor : formPayload.selecteddoctor_1,          
      scheduledate : formPayload.scheduledate_1,   
      selectedhour : formPayload.selhoursvalues_1,   
      selectedminute : formPayload.selminvalues_1,   
      selectedmeridiem : formPayload.selmerivalues_1,   
      order_id : order_id,   
      doctor_visite:sno,   
    };
  }else if(sno==2){
    var postObject = {
      admin_id       : admin_id,      
      selecteddoctor : formPayload.selecteddoctor_2,          
      scheduledate : formPayload.scheduledate_2,   
      selectedhour : formPayload.selhoursvalues_2,   
      selectedminute : formPayload.selminvalues_2,   
      selectedmeridiem : formPayload.selmerivalues_2,     
      order_id : order_id, 
      doctor_visite:sno,     
    };
  }
  else if(sno==3){
    var postObject = {
      admin_id       : admin_id,      
      selecteddoctor : formPayload.selecteddoctor_3,          
      scheduledate : formPayload.scheduledate_3,   
      selectedhour : formPayload.selhoursvalues_3,   
      selectedminute : formPayload.selminvalues_3,   
      selectedmeridiem : formPayload.selmerivalues_3,     
      order_id : order_id,      
      doctor_visite:sno,
    };
  }
  else if(sno==4){
    var postObject = {
      admin_id       : admin_id,      
      selecteddoctor : formPayload.selecteddoctor_4,          
      scheduledate : formPayload.scheduledate_4,   
      selectedhour : formPayload.selhoursvalues_4,   
      selectedminute : formPayload.selminvalues_4,   
      selectedmeridiem : formPayload.selmerivalues_4,     
      order_id : order_id,   
      doctor_visite:sno,   
    };
  }    
 
    this.setState({Loading:true});     
    var qs = require('qs');      
    axios.post(apiUrl+"ProductBookingHistory/assignDoctor",qs.stringify(postObject)).then(res => {
      this.setState({Loading:false});  
      if(res.data.status === "success"){           
         this.setState({doctorassignlist:res.data.booking_result});
         this.productorderdetails();
        }else{


        }
      });
}

validateForm(sno) {
  var selecteddoctor;
  var scheduledate;
  var selhoursvalues;
  var selminvalues;
  var selmerivalues;
  const formPayload = this.state;
  let errors = {};
  let formIsValid = true;
		
  if(sno==1)
  {
      selecteddoctor =formPayload.selecteddoctor_1;
      scheduledate = formPayload.scheduledate_1;
      selhoursvalues = formPayload.selhoursvalues_1;
      selminvalues =formPayload.selminvalues_1;
      selmerivalues = formPayload.selmerivalues_1;
  }else if(sno==2)
  {
      selecteddoctor =formPayload.selecteddoctor_2;
      scheduledate = formPayload.scheduledate_2;
      selhoursvalues = formPayload.selhoursvalues_2;
      selminvalues =formPayload.selminvalues_2;
      selmerivalues = formPayload.selmerivalues_2;
  }else if(sno==3)
  {
      selecteddoctor =formPayload.selecteddoctor_3;
      scheduledate = formPayload.scheduledate_3;
      selhoursvalues = formPayload.selhoursvalues_3;
      selminvalues =formPayload.selminvalues_3;
      selmerivalues = formPayload.selmerivalues_3;
  }else if(sno==4)
  {
      selecteddoctor =formPayload.selecteddoctor_4;
      scheduledate = formPayload.scheduledate_4;
      selhoursvalues = formPayload.selhoursvalues_4;
      selminvalues =formPayload.selminvalues_4;
      selmerivalues = formPayload.selmerivalues_4;
  }

		if (!selecteddoctor) {
			formIsValid = false;
			$('.errorselecteddoctor_'+sno).html('<span class="errorspan">Please Choose The Doctor</span>');
		}else if(selecteddoctor){
			$('.errorselecteddoctor_'+sno).html('');
		}
    if (!scheduledate) {
			formIsValid = false;
			$('.errorscheduledate_'+sno).html('<span class="errorspan">Please Choose The Date</span>');
		}else if(scheduledate){
			$('.errorscheduledate_'+sno).html('');
		}
    
    if (!selhoursvalues && !selminvalues && !selmerivalues) {
      formIsValid = false;
      $('.errortime_'+sno).html('<span class="errorspan">Please choose hours and minutes</span>');
    }else if (selhoursvalues && selminvalues && selmerivalues){
      $('.errortime_'+sno).html('');
    }

    this.setState({
			errors: errors
		});
		return formIsValid;


  }

   doctor_allocate(sno,assigndataarray)
   {
    var doctorlist = this.state.doctorlist; 
    const {selectedHours, selectedMinutes,selectedMeridiem} = this.state;
     const hours = [
      { value: '01', label: '1' },
      { value: '02', label: '2' },
      { value: '03', label: '3' },
      { value: '04', label: '4' },
      { value: '05', label: '5' },
      { value: '06', label: '6' },
      { value: '07', label: '7' },
      { value: '08', label: '8' },
      { value: '09', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
  ];
  const minutes =[
      { value: '05', label: '00' },
      { value: '05', label: '05' },
      { value: '10', label: '10' },
      { value: '15', label: '15' },
      { value: '20', label: '20' },
      { value: '25', label: '25' },
      { value: '30', label: '30' },
      { value: '35', label: '35' },
      { value: '40', label: '40' },
      { value: '45', label: '45' },
      { value: '50', label: '50' },
      { value: '55', label: '55' }            
  ];
  const meridiem =[
      { value: 'AM', label: 'am' },
      { value: 'PM', label: 'pm' },
  ];
      var scheduledate;
      var selectedHour;
      var selectedMinute;
      var selectedMeridiems;      
      var assigndata = this.state.doctorassignlist[sno-1];
      var assigneddoctor = '';
      var assigneddate = '';
      var assignstatus = false;
      if(assigndata !== undefined)
      {
          assigneddoctor= assigndata.doctor_id
          assignstatus = true;            
          // assigneddate = moment(assigndata.date).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
      }      
      if(sno==1)
      {
        if((assigndataarray!=undefined) && (assigndataarray.no_visiting==sno))
        {
          const assigndate = new Date(assigndataarray.date).toString();
          scheduledate =  moment(assigndate).toDate();
          selectedHour = { value: assigndataarray.hours, label:  assigndataarray.hours };
          selectedMinute= { value: assigndataarray.minutes, label:  assigndataarray.minutes };
          selectedMeridiems={ value: assigndataarray.meridiam, label:  assigndataarray.meridiam };

        }else{
         scheduledate = this.state.selecteddate_1;
         selectedHour = this.state.selectedHours_1;
         selectedMinute= this.state.selectedMinutes_1;
         selectedMeridiems=this.state.selectedMeridiem_1;
        }
      }else if(sno==2)
      {
        if((assigndataarray!=undefined) && (assigndataarray.no_visiting==sno))
        {
          const assigndate = new Date(assigndataarray.date).toString();
          scheduledate =  moment(assigndate).toDate();
          selectedHour = { value: assigndataarray.hours, label:  assigndataarray.hours };
          selectedMinute= { value: assigndataarray.minutes, label:  assigndataarray.minutes };
          selectedMeridiems={ value: assigndataarray.meridiam, label:  assigndataarray.meridiam };

        }else{
          scheduledate = this.state.selecteddate_2;
          selectedHour = this.state.selectedHours_2;
          selectedMinute= this.state.selectedMinutes_2;
          selectedMeridiems=this.state.selectedMeridiem_2;
        }
      }else if(sno==3)
      {
        if((assigndataarray!=undefined) && (assigndataarray.no_visiting==sno))
        {
          const assigndate = new Date(assigndataarray.date).toString();
          scheduledate =  moment(assigndate).toDate();
          selectedHour = { value: assigndataarray.hours, label:  assigndataarray.hours };
          selectedMinute= { value: assigndataarray.minutes, label:  assigndataarray.minutes };
          selectedMeridiems={ value: assigndataarray.meridiam, label:  assigndataarray.meridiam };

        }else{
          scheduledate = this.state.selecteddate_3;
          selectedHour = this.state.selectedHours_3;
          selectedMinute= this.state.selectedMinutes_3;
          selectedMeridiems=this.state.selectedMeridiem_3;
        }
      }else if(sno==4)
      {
        if((assigndataarray!=undefined) && (assigndataarray.no_visiting==sno))
        {
          const assigndate = new Date(assigndataarray.date).toString();
          scheduledate =  moment(assigndate).toDate();
          selectedHour = { value: assigndataarray.hours, label:  assigndataarray.hours };
          selectedMinute= { value: assigndataarray.minutes, label:  assigndataarray.minutes };
          selectedMeridiems={ value: assigndataarray.meridiam, label:  assigndataarray.meridiam };

        }else{
        scheduledate = this.state.selecteddate_4;
        selectedHour = this.state.selectedHours_4;
        selectedMinute= this.state.selectedMinutes_4;
        selectedMeridiems=this.state.selectedMeridiem_4;
        }
      }     
      return (
      <>
      <div className="confirm-action">
			<p>Please conform the visiting date both user and doctor will be notified.</p>
			<a href="javascript://" onClick={this.cancelRideConfirm.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.cancelRideConfirm.bind(this, "no")} className="btn">No</a>
		</div>

        <form id={"doctor_assign"+sno} onSubmit={ e => { this.handleFormSubmit(this,sno,this.state.orderid); e.preventDefault(); }}>
          <input type="hidden"  name="no_of_visite"   value={sno}/>
          <tr key={this.state.orderid}>
            <td className="number-width">{sno}</td>
            <td className="select-dr">
            <select  onChange={this.handleClickSelecetDoctor} name={'selecteddoctor_'+sno} id={'selecteddoctor_'+sno} >   
                  <option  data-key='0'   data-visite={sno} >Select Doctor</option>                 
                    {doctorlist.map((doctorlist) => <option data-visite={sno} data-key={doctorlist.value} value={doctorlist.value} selected={doctorlist.value == assigneddoctor}  >{doctorlist.label}</option>)}
                    </select>
                    <div className={'errorselecteddoctor_'+sno} ></div>
               </td>
            <td className="td-width-date"> 
               <DatePicker
                    data-visite={sno}
                     placeholderText="Choose Visiting Date"
                      dateFormat="dd/MM/yyyy"
                       selected={scheduledate}
                        className="form-control"
                        id={'scheduledate_'+sno} 
                     onChange={this.handleChangeDate.bind(this,sno)}  />

                    <div className={'errorscheduledate_'+sno} ></div>
            </td>
            <td className="td-width"> 
              <div className="time-row">
									<div className={ 'row-hour errorhour_'+sno}>
                    <Select 
                      id={'selectedhour_'+sno}
                      value={selectedHour?selectedHour:{ value: 'HH', label: 'HH'}}
                      options={hours} 
                      onChange={this.handleChangeHr.bind(this,sno)}
                      isSearchable={false}
                    />
									</div>
									<div className={"row-min errorminutes_"+sno}>
									<Select 
                    name={'selectedminute'+sno} 
                    value={selectedMinute?selectedMinute:{ value: 'MM', label: 'MM'}}
                    options={minutes} 
                    onChange={this.handleChangeMinutes.bind(this,sno)}
                    isSearchable={false}
									/>
									</div>
									<div className={"row-merridiem errormeridiem_"+sno}>
									<Select 
                    id={'selectedsecond'+sno} 
                    value={selectedMeridiems?selectedMeridiems:{ value: 'AM/PM', label: 'AM/PM'}}
                    options={meridiem} 
                    onChange={this.handleChangeMeridiem.bind(this,sno)}
                    isSearchable={false}
									/> 
								</div>
                </div>
                <div className={"errortime_"+sno}></div>
						</td> 
            <td>
              {  (assignstatus == true)? <button type='button' className='btn btn-primary' id={"assignbtn_"+sno}  > Assigned </button> :  <button className='btn btn-primary' id={"assignbtn_"+sno}  > Assign </button>}
            </td>         			    
          </tr>
          </form>
            </>
        );
      // return helperlistDetails;        
   }

   cancelConfirmPopup = (sno,orderID) => {
		// this.setState({removekey: i, domain_id: domainID})	
		$('.confirm-action').addClass('show');	
    
    this.setState({assignorderid:sno,assignorderid:orderID});


	}

	cancelRideConfirm = (confirmstatus) => {
		if(confirmstatus == 'yes'){
			// this.removeClick(this.state.removekey,this.state.domain_id);
			$('.confirm-action').removeClass('show');
      
      
      this.assignFormSubmit(this.state.assignorderid,this.state.assignorderid);

		}else{
			$('.confirm-action').removeClass('show');	
		}
			
	}


   producttotalamount(){
    var productdetails = this.state.productdetails;
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {
        let tot_amt = 0;
        if (Object.keys(productdetails).length > 0) {
           
          const helpertotamt = productdetails.map(
            (productdetails, Index) => {

                 tot_amt += parseInt(productdetails.price);
            }
          );
          return tot_amt;
        }
      }
    }
   }
  render() {
   
	
  if(this.state.productorderlistdata!==undefined){
    var productorderlistdata = this.state.productorderlistdata;
  }
  var datetime = moment(productorderlistdata.created_on).format('MMM-DD-Y h:m:s a');
    return (
      <div className="wrapper">  
    <Header />
    <MenuSidebar currentpage="DoctorVisitManagement" />  
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3> Assign Doctors  </h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.username}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.specialist_name}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.catname}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.productname}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Ordered on<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{datetime}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Total Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>   ${this.producttotalamount()}</p>
                        </div>
                      </div>



                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Assign Doctor</b></p>
                        </div>
                       
                      </div>
                      <div className="car-listing-row table-avrech">
              <div className="overflow">

                
                 <Table striped bordered hover>
                  
                    <tbody>
                    {this.productorderdetails()}
                    </tbody> 
                </Table> 
    
			        	</div></div>
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default connect()(withRouter(DoctorAssign));