import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useState } from "react";
import { Button } from 'react-bootstrap';

	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			cat_name:'',
			short_description:'',
			description:'',
			image:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active'
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);

    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file
	  });
	};
	reader.readAsDataURL(file);
  }

  handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}

		var img_name = this.state.image;
		var postObject ={
			admin_id: localStorage.getItem('admin_id'),
			name : formdata.cat_name,
			description: formdata.description,
			short_description: formdata.short_description,
			image : formdata.image,
			status: status
		}
		let formData = new FormData();
		for(let k in postObject) {
			formData.append(k, postObject[k]);
		}
		axios.post(apiUrl+"ProductCategory/add",formData,config).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/ProductCategory'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
	}

	validateForm() {
		const {cat_name,short_description} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		
		  if(!cat_name){
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcat_name').html('');
		}
	
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}	
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	

  render() {

	let preview = '';
	const {selectedOption} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="ProductCategory" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Product Category</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Category Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name}/>
					<div className="errorcat_name"></div>
				</div>
				<div className="form-group">					
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
					<div className="errorshort_description"></div>
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));