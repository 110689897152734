/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_SETTINGDATA, SET_SETTINGDATA, GET_UPDATESETTING, SET_UPDATESETTING, GET_PLUGINCONTENT, SET_PLUGINCONTENT,
  GET_UPDATEPLUGINCONTENT, SET_UPDATEPLUGINCONTENT } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';


export const watchGetPluginContent = function* () {
  yield takeEvery(GET_PLUGINCONTENT, workerGetPluginContent);
}

function* workerGetPluginContent({adminId}) {
  try {
    const uri = apiUrl+'adminpanel/getplugincontent?adminId='+adminId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PLUGINCONTENT, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 

/*save the plugin content*/

export const watchGetUpdatePluginContent = function* () {
  yield takeEvery(GET_UPDATEPLUGINCONTENT, workerGetUpdatePluginContent);
}

function* workerGetUpdatePluginContent({ formPayload }) {
  try {
      
 const result = yield call(getUpdatePluginContent, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATEPLUGINCONTENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdatePluginContent(formPayload) {
   return Axios.post(apiUrl+'adminpanel/saveplugincontent', formPayload);
} 


export const watchGetSettingData = function* () {
  yield takeEvery(GET_SETTINGDATA, workerGetSettingData);
}

function* workerGetSettingData({ formPayload }) {
  try {
      
 const result = yield call(getSettingData, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_SETTINGDATA, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getSettingData(formPayload) {
   return Axios.post(apiUrl+'adminpanel/adminsettings', formPayload);
}  

/*save the settings*/

export const watchGetUpdateSetting = function* () {
  yield takeEvery(GET_UPDATESETTING, workerGetUpdateSetting);
}

function* workerGetUpdateSetting({ formPayload }) {
  try {
      
 const result = yield call(getUpdateSetting, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATESETTING, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateSetting(formPayload) {
   return Axios.post(apiUrl+'adminpanel/settingsave', formPayload);
} 