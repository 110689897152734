import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,guideURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const guide_id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            guide_id: guide_id,
			cat_name:'',
			description:'',
			short_description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image:'',
			image_name: '',
			image_preview: '',
			fileExtension: '',
			editid:0,
			send_as_email_checked:false,
			emailtemplatelist:[],
			template_name:'',
			selectedtemplate:'',
			selectedtemplate_value:'',
		};
		// this.props.getCategoryDetailList(guide_id);
		var qs = require('qs');
		var catid = this.state.guide_id;

		axios.get(apiUrl+"Guide/listdetail?id="+catid).then(res => {
			this.setState({image_preview:res.data.guidlist.image,image:res.data.guidlist.image,cat_name:res.data.guidlist.name,description:res.data.guidlist.description,short_description:res.data.guidlist.short_description,fileExtension:res.data.fileext,editid:1,selectedtemplate:res.data.selectedtemplate,selectedtemplate_value:res.data.emailtemplate_id});
			if(res.data.guidlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(parseInt(res.data.guidlist.send_as_email) === 0){
				this.setState({send_as_email_checked: false});
			}else{
				this.setState({send_as_email_checked: true});
			}
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'cat_trending'){
      	this.setState({cat_trending_checked: !this.state.cat_trending_checked});
      }  		
	  if(name == 'send_as_email'){
		this.setState({send_as_email_checked: !this.state.send_as_email_checked})
	}
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}

	

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var send_as_email = 0;
				if(this.state.send_as_email_checked == true){
					var send_as_email = 1;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					cat_name: formPayload.cat_name,
					cat_description: formPayload.description,
					short_description:formPayload.short_description,	
					status:  status,
					send_as_email:  send_as_email,
					emailtemplate_id:this.state.selectedtemplate_value,

					id: formPayload.guide_id,
					image: this.state.editid===0 ? formPayload.image:''
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"Guide/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/Guide'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {cat_name,description,short_description,image} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!cat_name) {
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			$('.errorcat_name').html('');
		}

		// if (!description) {
		// 	formIsValid = false;
		// 	$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		// }else if(description){
		// 	$('.errordescription').html('');
		// }

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}
		if (this.state.editid==0) {
		if (!image) {
			formIsValid = false;
			$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
				}else{
					$('.errorfile').html('');
				}
				if (image) {		
		if (!image.name.match(/\.(jpg|jpeg|png|pdf|docs|txt|docx|doc)$/)) {
			formIsValid = false;
		 		$('.errorfile').html('<span class="errorspan">Please select valid type</span>');
		 	  
		  }else{
			$('.errorfile').html('');
		  }
		}
	}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	// if(Props.categorydetails !== this.props.categorydetails){
   		
    // 		if(Object.keys(Props.categorydetails).length > 0){
	// 			this.setState({Loading:false});

	// 				if(Props.categorydetails[0].status === "success"){

	// 					const formdata = Props.categorydetails[0].guidlist;
						
	// 					if(formdata.status === 'A'){
	// 						this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 					}else{
	// 						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

	// 					}

	// 					if( parseInt (formdata.form_builder_id) !==0 ){

	// 						this.setState({forms: 1,products:0});
	// 						this.setState({form_select: 'forms'});
	// 					}else{
	// 						this.setState({forms: 0,products:1});
	// 						this.setState({form_select: 'products'});
	// 					}
		

	// 					this.setState({image_preview:formdata.image});
	// 					this.setState({cat_name:formdata.cat_name});
	// 					this.setState({description: formdata.description});						
	// 					this.setState({short_description: formdata.short_description});
    //                     this.setState({guide_id: formdata.id});


                        
	// 				}
    // 		}
    // 	}

    // 	if(Props.categoryedit !== this.props.categoryedit){
    // 		if(Object.keys(Props.categoryedit).length > 0){
	// 			if(Props.categoryedit[0].status === "success"){
    // 					this.setState({ Loading: false });

    // 			const formpayload = Props.categoryedit[0].guidlist;

	// 			if(formpayload.status === 'A'){
	// 				this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 				}else{
	// 				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
	// 				}	
	// 				this.setState({image_preview:formpayload.image});
	// 				this.setState({cat_name:formpayload.cat_name});
	// 				this.setState({description: formpayload.description});						
	// 				this.setState({short_description: formpayload.short_description});
	// 				this.setState({guide_id: formpayload.id});
			
    //                 	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
    //            			scrollToTop();
    // 					setTimeout(
	// 					function() {
	// 						$('.success_message').html(this.props.history.push('/category'));
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
						
    // 		}else{
	// 				this.setState({ Loading: false });
	// 				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
	// 				scrollToTop();
    // 					setTimeout(
	// 					function() {
	// 						$('.success_message').html('');
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
	// 		}
	// 		}
    // 	}
		
    }
	componentDidMount() {
		document.title = PageTitle('Category Edit');
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'Guide/getemailTemplates?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					emailtemplatelist: [{value: '', label: 'Select Template'}].concat(res.data.emailtemplatelist)
				});
			
			}else{
				
			}
		});
	}
	handleClickSelecetemailTemplate = selectedtemplate => {

		 this.setState({ selectedtemplate});
		this.setState({ selectedtemplate_value : selectedtemplate.value });
     }
  render() {

 console.log(this.state.fileExtension,this.state.editid,"fileExtensionfileExtension")
  	  const {selectedOption,fileExtension,send_as_email_checked,selectedtemplate,emailtemplatelist} = this.state;

		let loaderimg = '';
		let preview = '';

		  const imagepre = guideURL+'/'+this.state.image_preview;

	if((this.state.fileExtension == 'jpg' || this.state.fileExtension == 'jpeg' || this.state.fileExtension =='png') && (this.state.editid==1)){
		$('.errorfile').html('');
	console.log(1)
	loaderimg = guideURL + '/'+this.state.image_preview;
	preview = <img className="img_class" src={loaderimg} alt="" />;
}else if((this.state.fileExtension == 'docs' ||this.state.fileExtension == 'doc' || this.state.fileExtension == 'pdf' || this.state.fileExtension =='txt'|| this.state.fileExtension =='docx') && (this.state.editid==1)){

	$('.errorfile').html('');
	console.log(2)
	loaderimg = guideURL + '/'+this.state.image_preview;
	preview =  <a href = {imagepre} target = "_blank">{this.state.image_preview} </a>;
}

else if((this.state.fileExtension == 'jpg' || this.state.fileExtension == 'jpeg' || this.state.fileExtension =='png') && (this.state.editid==0)){
	$('.errorfile').html('');
	console.log(3)
	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
}else if((this.state.fileExtension == 'docs' ||this.state.fileExtension == 'doc'|| this.state.fileExtension == 'pdf' || this.state.fileExtension =='txt'|| this.state.fileExtension =='docx') && (this.state.editid==0)){
	console.log(4)
	$('.errorfile').html('');
	preview =  <a href = {this.state.image_preview} target = "_blank">{this.state.image_name} </a>;
}else if(this.state.fileExtension !=''){
	$('.errorfile').html('<span class="errorspan">Please select valid type</span>');
}


// const categoryArray = this.state.templ.map ((categorylist, index) => ({
// 	id: index,
// 	name: categorylist,
// 	}));
// 	// Dynamically create select list
// 	let categorys = [];
// 	categoryArray.map(item =>
// 	categorys.push({ label: item.name.label, value: item.name.value }),
// 	); 
  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Guide" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Guide Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>

				<div className="form-group">					
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
					<div className="errorshort_description"></div>
				</div>
				<div className="form-group">
					<label>Guide Upload(jpeg,jpg,png,pdf,doc,docx,txt):</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div> 
			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">
							<input onChange={this.handleInputChange} type="checkbox"  name="send_as_email" checked={this.state.send_as_email_checked}/>
							<label> Send as Email</label>
					</div>
					{this.state.send_as_email_checked == true ? <div className="form-group emailtemp_div">
					<label>Email Templates<span class="required" style={{ color: "red" }} > * </span> : </label>


					
						<Select 
                         options={emailtemplatelist}  
                         value={selectedtemplate?selectedtemplate:{ value: '0', label: 'Select Template' }}
                         onChange={this.handleClickSelecetemailTemplate}
                         placeholder="Select Template" />
				

				    {/* <select  onChange={this.handleClickSelecetemailTemplate}  >                    
                    {this.state.emailtemplatelist.map((emailtemplatelist) => <option key={emailtemplatelist.value} data-key={emailtemplatelist.value} value={selectedtemplate?selectedtemplate:{ value: '0', label: 'Select Template' }} >{emailtemplatelist.label}</option>)}
                    </select> */}

					<div className="errortemplate_name"></div>
				 </div>:null}
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (guide_id) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,guide_id });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));