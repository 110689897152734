import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import moment from 'moment';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,appointmentURL,mediaUrl} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const appointmentid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      appointmentid: appointmentid,
      appointmentlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :appointmentid
    };

    axios.post(apiUrl+"AppointmentHistory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ appointmentlistdata: res.data.datalistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('appointment View');
   }
   downloadImage(url, name) {
		fetch(url)
		.then(resp => resp.blob())
		.then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			// the filename you want
			a.download = name;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		})
		.catch(() => alert('An error sorry'));
	}
  render() {
  if(this.state.appointmentlistdata!==undefined){
    var appointmentlistdata = this.state.appointmentlistdata;
  }


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="AppointmentHistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Appointment History</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Appointment ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.appointment_id}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Doctor<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.doctor_name}</p>
                        </div>
                      </div>

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Book For<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.book_for}</p>
                        </div>
                      </div> */}
                      

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.name}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petname}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Doctor Visit<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petvisit}</p>
                        </div>
                      </div>


                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.pettype}</p>
                        </div>
                      </div>
                      {appointmentlistdata.petgender&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Gender<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petgender}</p>
                        </div>
                      </div>}
                      {appointmentlistdata.petcolor&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Color<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petcolor}</p>
                        </div>
                      </div>}

                      {appointmentlistdata.petweight&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Weight<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petweight}</p>
                        </div>
                      </div>}

                      {appointmentlistdata.petage!=''&&appointmentlistdata.petage!=0&&appointmentlistdata.petage!=undefined&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Pet Age<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.petage}</p>
                        </div>
                      </div>}


                      {appointmentlistdata.phone&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Phone Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.phone}</p>
                        </div>
                      </div>}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.email}</p>
                        </div>
                      </div>

                      {(appointmentlistdata.canvas_image !=''&& appointmentlistdata.canvas_image !=null)&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Affected Area<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>
                            <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" onClick={this.downloadImage.bind(this,mediaUrl+ appointmentlistdata.canvas_image, 'Download.jpg')}>
										<path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg><img src={mediaUrl+ appointmentlistdata.canvas_image} />

                            </p>
                        </div>
                      </div>}
                      
                      {(appointmentlistdata.user_comments !=''&& appointmentlistdata.user_comments !=null)&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Comments<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.user_comments}</p>
                        </div>
                      </div>}

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>medical_certificate<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.name}</p>
                        </div>
                      </div> */}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Appointment On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{moment(appointmentlistdata.appointment_date).format('DD MMM YYYY')}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Booked On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{moment(appointmentlistdata.created_date).format('DD MMM YYYY')}</p>
                        </div>
                      </div>

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Doctor Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.paid_status}</p>
                        </div>
                      </div> */}

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.transaction_id}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Charge ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{appointmentlistdata.charge_id}</p>
                        </div>
                      </div> */}
                      {/* { (appointmentlistdata.original_amount !='' && appointmentlistdata.original_amount !=null  )?
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Original Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${appointmentlistdata.original_amount}</p>
                        </div>
                      </div> : <></>} */}


                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    appointmentlistview: state.appointmentlistview
  }
}

export default (withRouter(View));