import { SET_PRODUCTDETAILLIST } from '../actions';

const productdetails = (state = [], action) => {
  switch (action.type) {
	case SET_PRODUCTDETAILLIST:
      return [...action.value];  
    default: return state;
  }
}

export default productdetails;
