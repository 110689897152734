/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDCATEGORY, GET_ACTIVATECATEGORY, SET_ACTIVATECATEGORY, SET_ADDCATEGORY, GET_EDITCATEGORY, SET_EDITCATEGORY, GET_CATEGORYLIST, SET_CATEGORYLIST, GET_DELETECATEGORY, SET_DELETECATEGORY, GET_IMPORTCATEGORY, SET_IMPORTCATEGORY,GET_CATEGORYDETAILLIST, SET_CATEGORYDETAILLIST,GET_VIEWCATEGORYLIST, SET_VIEWCATEGORYLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*export const watchGetUserList = function* () {
  yield takeEvery(GET_USERLIST, workerGetUserList);
}

function* workerGetUserList() {
	  
      var url = apiUrl+'user/list';
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_USERLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}*/


/*save the user*/

export const watchGetCategoryList = function* () {
  yield takeEvery(GET_CATEGORYLIST, workerGetCategoryList);
}

function* workerGetCategoryList({ formVal }) {
  try {
      
 const result = yield call(getCategoryList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_CATEGORYLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getCategoryList(formPayload) {
   return Axios.post(apiUrl+'specialist/list', formPayload);
} 




/*get user details*/ 

export const watchGetCategoryDetailList = function* () {
  yield takeEvery(GET_CATEGORYDETAILLIST, workerGetCategoryDetailList);
}

function* workerGetCategoryDetailList({ categoryId }) {

    var url = apiUrl+'specialist/listdetail?id='+categoryId;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_CATEGORYDETAILLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 


/*save the user*/

export const watchGetCategoryAdd = function* () {
  yield takeEvery(GET_ADDCATEGORY, workerGetCategoryAdd);
}

function* workerGetCategoryAdd({ formPayload }) {
  try {
      console.log(formPayload)
 const result = yield call(getAddCategory, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCATEGORY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddCategory(formPayload) {
   return Axios.post(apiUrl+'specialist/add', formPayload);
} 


/*edit the user*/

export const watchGetEditCategory = function* () {
  yield takeEvery(GET_EDITCATEGORY, workerGetEditCategory);
}

function* workerGetEditCategory({ formPayload }) {
  try {
      
 const result = yield call(getEditCategory, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCATEGORY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditCategory(formPayload) {
   return Axios.post(apiUrl+'specialist/edit', formPayload);
} 


/*delete the user*/

export const watchGetDeleteCategory = function* () {
  yield takeEvery(GET_DELETECATEGORY, workerGetDeleteCategory);
}

function* workerGetDeleteCategory({ formPayload }) {
  try {
      
 const result = yield call(getDeleteCategory, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETECATEGORY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteCategory(formPayload) {
   return Axios.post(apiUrl+'specialist/delete', formPayload);
} 




/*change status the user*/

export const watchActivateCategory = function* () {
  yield takeEvery(GET_ACTIVATECATEGORY, workerActivateCategory);
}

function* workerActivateCategory({ formPayload }) {
  try {
      
 const result = yield call(getActivateCategory, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATECATEGORY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateCategory(formPayload) {
   return Axios.post(apiUrl+'specialist/change_status', formPayload);
} 

/*list of category*/
export const watchGetViewCategoryList = function* () {
  yield takeEvery(GET_VIEWCATEGORYLIST, workerGetViewCategoryList);
}

function* workerGetViewCategoryList({ categoryid }) {
  try {
    const result = yield call(getViewCategoryList, categoryid);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VIEWCATEGORYLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getViewCategoryList(categoryid){
   return Axios.post(apiUrl+'specialist/view', categoryid);
}

