import login from "./login";
import {settings, plugincontent, updateplugincontent} from "./settings";
import changepassword from "./changepassword";
import updatesetting from './updatesetting';
import forgetpassword from './forgetpassword';
import profile from './profile';
import updateprofile from './updateprofile';

import companylist from './companylist';
import companyadd from './companyadd';
import companyedit from './companyedit';
import companydetails from './companydetails'

import fontlist from './fontlist';
import fontname from './fontname';
import fontadd from './fontadd';
import userlist from './userlist';
import changestatus from './changestatus';
import fontedit from './fontedit';
import fontdetails from './fontdetails'

import exportuser from './exportuser';
import fontdelete from './fontdelete';
import useredit from './useredit';
import userimport from './userimport';
import userdetails from './userdetails';
import useradd from './useradd';
import userlistview from './userview';



import templatelist from './templatelist';
import templatedetail from './templatedetail';
import templateadd from './templateadd';
import templatedelete from './templatedelete';
import templateedit from './templateedit';



import paymenthistorylist from './paymenthistorylist';
import paymenthistoryview from './paymenthistoryview';

import  dashboardlist from './dashboardlist';

import cmsadd from './cmsadd';
import cmsedit from './cmsedit';
import cmsdetail from './cmsdetail';
import cmslist from './cmslist';
import cmsactive from './cmsactive';

import categoryadd from './categoryadd';
import categorydetails from './categorydetails';
import categorylistview from './categorylistview';
import categorylist from './categorylist';
import categoryedit from './categoryedit';
import deletecategory from './deletecategory';
import categoryactive from './categoryactive';


import productadd from './productadd';
import productdetails from './productdetails';
import productlistview from './productlistview';
import productlist from './productlist';
import productedit from './productedit';
import deleteproduct from './deleteproduct';
import productactive from './productactive';


import couponlist from './couponlist';
import coupondetails from './coupondetails';
import couponadd from './couponadd';
import couponedit from './couponedit';

import {packageadd, packageedit, packagedetail, packagelist, packageactive} from './packageAll';

import {formadd, formedit, formdetail, formlist, formactive, entrieslist, entriesview} from './formbuilder';

import { combineReducers } from "redux";


const rootReducer = combineReducers({
  login: login,
  settings      : settings,
  updatesetting : updatesetting,
  changepassword: changepassword,
  forgetpassword: forgetpassword,
  profile       : profile,
  updateprofile : updateprofile,
  companylist   : companylist,
  companyadd   : companyadd,
  companyedit   : companyedit,
  companydetails : companydetails,

  fontlist           : fontlist,
  fontname           : fontname,
  fontadd            : fontadd,
  userlist			 : userlist,
  exportuser     : exportuser,
  fontdelete         : fontdelete,
  useredit: useredit,
  userimport: userimport,
  fontedit   : fontedit,
  fontdetails : fontdetails,
  
  userdetails: userdetails,
  
  useradd      : useradd,
  userlistview  : userlistview,
 
  changestatus: changestatus,

    templatelist  : templatelist,
  templatedetail: templatedetail,
  templateadd   : templateadd,
  templatedelete : templatedelete,
  templateedit: templateedit,
  
  categoryadd       : categoryadd,
  categorylist      : categorylist,
  deletecategory    : deletecategory,
   categorydetails   : categorydetails,
  categoryedit      : categoryedit,
  categorylistview   : categorylistview,
  categoryactive     : categoryactive,


  productadd       : productadd,
  productlist      : productlist,
  deleteproduct    : deleteproduct,
   productdetails   : productdetails,
  productedit      : productedit,
  productlistview   : productlistview,
  productactive     : productactive,
 

  paymenthistorylist : paymenthistorylist,
  paymenthistoryview : paymenthistoryview,

  dashboardlist : dashboardlist,

  cmsadd        : cmsadd,
  cmslist       : cmslist,
  cmsedit       : cmsedit,
  cmsdetail     : cmsdetail,
  cmsactive     : cmsactive,

  packageadd        : packageadd,
  packagelist       : packagelist,
  packageedit       : packageedit,
  packagedetail     : packagedetail,
  packageactive     : packageactive, 

  formadd : formadd,
  formedit: formedit,
  formdetail: formdetail,
  formlist: formlist,
  formactive: formactive,
  //form entries list
  entrieslist: entrieslist,
  entriesview : entriesview,
  
  couponlist           : couponlist,
  coupondetails           : coupondetails,
  couponadd            : couponadd,
  couponedit            : couponedit,

  updateplugincontent: updateplugincontent,
  plugincontent: plugincontent


});

export default rootReducer;
