import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_PAYMENTVIEWLIST } from '../../actions';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import moment from 'moment';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const paymenthistoryid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      paymenthistoryid: paymenthistoryid,
      paymenthistoryview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :paymenthistoryid
    };
    this.props.getPaymentViewList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('Payment View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.paymenthistoryview!==this.state.paymenthistoryview){
        if(Props.paymenthistoryview[0].status == "success"){
          this.setState({paymenthistoryview: Props.paymenthistoryview[0].paymenthistoryview[0],paymenthistorylist: Props.paymenthistoryview[0].paymenthistoryview_result}) 
        }
      }
   }

    paymenthistorylist() {
    var paymenthistorylist = this.state.paymenthistorylist;
    console.log('paymenthistorylistcvcvcvcvc',paymenthistorylist);
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.map(
            (paymenthistorylist, Index) => {
             
             let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var order_status = paymenthistorylist.order_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type

            var username =  paymenthistorylist.username;
            var email = paymenthistorylist.email;
            var mobileno = paymenthistorylist.mobileno;

            var Product = paymenthistorylist.product_name == null ?  paymenthistorylist.product_name = 'N/A' : paymenthistorylist.product_name;
            var Category = paymenthistorylist.category_search_name == null ?  paymenthistorylist.category_search_name = 'N/A' : paymenthistorylist.category_search_name;
           
           

            var paymenttype;
            if(payment_type && payment_type!== null && payment_type!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype = String(payment_type).charAt(0).toUpperCase() + String(payment_type).slice(1);
              //payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

              var paidTo;
            if(paymenthistorylist.payment_for && paymenthistorylist.payment_for!== null && paymenthistorylist.payment_for!== ''){
              //paymenttype = payment_type.toUpperCase();
              paidTo = String(paymenthistorylist.payment_for).charAt(0).toUpperCase() + String(paymenthistorylist.payment_for).slice(1); 
                 // paymenthistorylist.payment_for.toUpperCase();
            }else{
              paidTo = 'N/A';
            }


            let catImg = '';
             let preview = ''; 

            if (paymenthistorylist.product_image!== null && paymenthistorylist.product_image!== '') {
            catImg = productURL + "/" + paymenthistorylist.product_image;
          preview = <img className="" src={catImg} alt="" />;
        }
        var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
  
              return (
                
                <div className="form-row">
                   <div className="form-left">
                 
                 <h4 style={{fontWeight: 'bold'}}>Order Placed Details</h4>
                 <div className="payment-histroy details_left">

                 <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Product<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{Product}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b> Quantity<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.product_quantity}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Category<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{Category}</p>
                      </div>
                      </div>
                     <div className="view-details">
                        <div className="view-details-one">
                          <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">   
                          <p>{username}</p>
                          </div>
                          </div>
                          
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>User Contact<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{mobileno}</p>
                      </div>
                      </div>

                      
                       <div className="view-details">
                        <div className="view-details-one">
                      <p><b> Amount<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>${paymenthistorylist.original_amount}</p>
                      </div>
                      </div>

                      

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>  Payment Status<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paid_status.charAt(0).toUpperCase() + paid_status.slice(1)}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>  Payment Type<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{payment_plan.charAt(0).toUpperCase() + payment_plan.slice(1)}</p>
                      </div>
                      </div>

                     {(payment_plan =='recurring')?
                      <>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>  Subscription Plan<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.subscription_name.charAt(0).toUpperCase() + paymenthistorylist.subscription_name.slice(1)}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>  Subscription Days<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.subscription_days.charAt(0).toUpperCase() + paymenthistorylist.subscription_days.slice(1)}</p>
                      </div>
                      </div> 

                      </>:''}
                      


                      

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>  Order Status<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{order_status.charAt(0).toUpperCase() + order_status.slice(1)}</p>
                      </div>
                      </div>
                     
                     
                      
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Placed on<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{moment(paymenthistorylist.created_date).format('MM/DD/YYYY')}</p>
                      </div>
                      </div>


                       {(paymenthistorylist.completed_on !== null)?
                        <>
                          <div className="view-details">
                        <div className="view-details-one">
                        <p><b>Completed on<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p>{moment(paymenthistorylist.completed_on).format('MM/DD/YYYY')}</p>
                        </div>
                        </div>
                         </>:<>
                        </>
                      }



                      </div>
                      </div>

                      <div className="form-right">
                       <h3 style={{fontWeight: 'bold'}}> </h3>
                      <h3 style={{fontWeight: 'bold'}}>Product Image</h3> 
                      <div className="payment-histroy details_left">
                      <div className="view-details">
                        </div>
                          <div className="view-details">
                          <div className="view-details-one">
                              {preview}
                          </div>
                          {/*<div className="view-details-two">
                              {preview}
                          </div>*/}
                        </div>
                    </div>
                  </div>
                 </div>
              
              );
            }
          );
          return helperlistDetails;
        }
      
    }
  }


  
  render() {

    var payment_plan = this.state.paymenthistoryview.payment_type == "onetime" ?  this.state.paymenthistoryview.payment_plan = 'N/A' : this.state.paymenthistoryview.payment_type;

    var payment_type  = this.state.paymenthistoryview.payment_type;

    var paid_status  = this.state.paymenthistoryview.paid_status;
if((this.state.paymenthistoryview.user_note!='')&&(this.state.paymenthistoryview.user_note!=null)){
  var user_note    = this.state.paymenthistoryview.user_note;
}else{
  var user_note    = 'N/A';
}


 var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
   

    return (
      <div className="wrapper"> 
 
    <Header />

    {(lasturrl ==='completed')?
    <>
     <MenuSidebar currentpage="payment-completed-history" />
     </>:<>
     <MenuSidebar currentpage="payment-history" />
    </>
    }
    
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                 {/* <h3> Payment History Details</h3>*/}
                </div>
                    {this.paymenthistorylist()}
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    paymenthistoryview: state.paymenthistoryview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentViewList: (paymenthistoryid) =>{
       dispatch({ type: GET_PAYMENTVIEWLIST,paymenthistoryid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));