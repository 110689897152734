import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $, { event } from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useState } from "react";
import { Button } from 'react-bootstrap';

	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
		
			productlist:[],
			doctorlist:[],
			selecteddoctor:'',
			selecteddocvalue:'',
			product_name:'',
			productid:'',
			provider_price:''
		};

		this.handleInputChange = this.handleInputChange.bind(this);

    }
	componentDidMount() {
	
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'providerinventory/getproviderproduct?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					productlist: [{value: '', label: 'Select Products'}].concat(res.data.productlist),
					doctorlist: res.data.doctorlist
				});
			
			}else{
				
			}
		});
	}

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	var id = event.target.options[selectedIndex].getAttribute('data-id');

	this.setState({
			product_name: keys,	productid: id
	});

}

handleClickSelecet = (selOption) => {
	console.log(selOption)
       
	let doctors = [];
	let selValue = [];
	if(selOption!== null){
		selOption.map(item => {
			selValue.push(item.value);
			doctors.push({ label: item.label, value: item.value });
		});
		this.setState({selecteddoctor:doctors});
		this.setState({  selecteddocvalue : selValue});
	}else{
		this.setState({selecteddoctor:''});
		this.setState({  selecteddocvalue : ''});
	}
	
	

 };


  handleSubmit = () => {
	if(this.validateForm()){
	const formdata = this.state;
	var qs = require('qs');


	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		product:formdata.product_name,
		productid: formdata.productid,
		selecteddocvalue: formdata.selecteddocvalue,
		provider_price:formdata.provider_price,
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"providerinventory/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/providerinventory'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
	}
  }

  validateForm() {
	const {provider_price,selecteddocvalue,product_name} = this.state;

	let errors = {};
	  let formIsValid = true;

	

	if (!provider_price) {
		formIsValid = false;
		$('.errorprovider_price').html('<span class="errorspan">Please enter price</span>');
	}else if(provider_price){
		$('.errorprovider_price').html('');
	}

	if (!selecteddocvalue) {
		formIsValid = false;
		$('.errordoctors').html('<span class="errorspan">Please select doctors</span>');
	}else if(selecteddocvalue){
		$('.errordoctors').html('');
	}

	if (!product_name) {
		formIsValid = false;
		$('.errorproducts').html('<span class="errorspan">Please select products</span>');
	}else if(product_name){
		$('.errorproducts').html('');
	}

	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {

	let preview = '';
	const {selectedOption,productlist,doctorlist} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Providerinventory" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Inventory</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Products<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetCategory}  value={productlist.cat_name}>                    
                    {this.state.productlist.map((productlist) => <option key={productlist.value} data-key={productlist.label} data-id={productlist.value} value={productlist.value} >{productlist.label}</option>)}
                    </select>
					<div className="errorproducts"></div>
				 </div>
				<div className="form-group">					
					<label>Inventory Stock / Doctor<span class="required" style={{ color: "red" }} > * </span> :</label>
						<input type="text" name="provider_price" onChange={this.handleInputChange} className="form-control" value={this.state.provider_price}  />
					<div className="errorprovider_price"></div>
				</div> 
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Doctors<span class="required" style={{ color: "red" }} > * </span> : </label>
				 

					<Select 
						value={this.state.selecteddoctor?this.state.selecteddoctor:""}
						options={this.state.doctorlist} 
						onChange={this.handleClickSelecet.bind(this)}
						isSearchable={true}
						isMulti
						placeholder={'Select doctors'}
						/>
					<div className="errordoctors"></div>
				 </div>
				

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));