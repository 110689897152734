import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import CKEditor from 'ckeditor4-react';

import {GET_TEMPLATEDETAIL, GET_TEMPLATEEDIT }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const templateid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			template_name:'',
			template_content:'',
			statusmessage:'',
			template_id: templateid,
			Loading:false,
			config: {
			extraAllowedContent: 'div(*)',
			allowedContent: true
			}
		};
		this.props.getTemplateDetail(templateid);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
        this.onEditorChange = this.onEditorChange.bind( this );


    }
	


   onEditorChange( evt ) {
        this.setState( {
            template_content: evt.editor.getData()
        } );
    }

    handleChange( changeEvent ) {
        this.setState( {
            template_content: changeEvent.target.value
        } );
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Template Edit');

		
		//CKEditor.instances.editor.setData(this.state.template_content);
    }


     handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			const formPayload = this.state;
			var qs = require('qs');
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				email_id : this.state.template_id,
				email_name: formPayload.template_name,
				email_template:formPayload.template_content,
			};
			this.props.getTemplateEdit(qs.stringify(postObject));
		}
	}

	validateForm() {
		const {template_name,template_content} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!template_name) {
			formIsValid = false;
			$('.errortemplate_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(template_name){
			$('.errortemplate_name').html('');
		}

		if (!template_content) {
			formIsValid = false;
			$('.errortemplate_content').html('<span class="errorspan">Please fill the field</span>');
		}else if(template_content){
			$('.errortemplate_content').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.templatedetail !== this.props.templatedetail){
    		if(Object.keys(Props.templatedetail).length > 0){
				this.setState({Loading:false});

					if(Props.templatedetail[0].status === "success"){

						const formdata = Props.templatedetail[0].templatelist;
					
						this.setState({template_name:formdata.email_name});
					    var callfunc = this;
						setTimeout(function () {
						callfunc.setState({template_content:formdata.email_template});
						}, 1000);
						//CKEditor.instances.setData( formdata.email_template );
					}
    		}
    	}
    	if(Props.templateedit !== this.props.templateedit){
    		if(Object.keys(Props.templateedit).length > 0){
    					this.setState({ Loading: false });

    			const formdata = Props.templateedit[0].templateedit;
				
				this.setState({template_name:formdata.email_name});
			    var callfunc = this;
				setTimeout(function () {
				callfunc.setState({template_content:formdata.email_template});
				}, 1000);
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.templateedit[0].message+'</h3></div>');


				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
    		}
    	}
    }

  render() {

  	const {template_content} = this.state;
  	if(template_content!==undefined){
  		var templatecontent = template_content;	
  	}
  return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="templates" />  

	
	<div className="content">	
		<div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row mail-template-sec">
			<div className="form-left mail-template-inner">
				<div className="form-group">
					<label>Template Name<span class="required" style={{ color: "red" }} > * </span>
:</label>
				    <input type="text" name="template_name" onChange={this.handleInputChange} className="form-control" value={this.state.template_name} />
					<div className="errortemplate_name"></div>
				</div>
				<div className="form-group">					
					<label>Email Content<span class="required" style={{ color: "red" }} > * </span>
:</label>
					<CKEditor
					id="dynamicckeditor"
	               // data={this.state.template_content}
	                data={templatecontent}
	                onChange={this.onEditorChange}
	                type="classic"
                     />

					<div className="errortemplate_content"></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
    templatedetail: state.templatedetail,
    templateedit:   state.templateedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTemplateDetail: (templateid) =>{
       dispatch({ type: GET_TEMPLATEDETAIL,templateid });
    },

    getTemplateEdit: (formPayload) =>{
       dispatch({ type: GET_TEMPLATEEDIT,formPayload });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));