/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PAYMENTLIST, SET_PAYMENTLIST, GET_PAYMENTVIEWLIST, SET_PAYMENTVIEWLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*get Payment list*/

export const watchGetPaymentList = function* () {
  yield takeEvery(GET_PAYMENTLIST, workerGetPaymentList);
}

function* workerGetPaymentList({ formVal }) {   
  try {     
 const result = yield call(getPaymentList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PAYMENTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getPaymentList(formPayload) {
   return Axios.post(apiUrl+'product/paymenthistorylist', formPayload);
} 

  /*view payment*/
export const watchGetViewPaymentList = function* () {
    yield takeEvery(GET_PAYMENTVIEWLIST, workerGetViewPaymentList);
  }
  
  function* workerGetViewPaymentList({ paymenthistoryid }) {
    try {
      const result = yield call(getPaymentViewList, paymenthistoryid);
      var resultArr = [];
      resultArr.push(result.data);
      yield put({ type: SET_PAYMENTVIEWLIST, value: resultArr });
    } 
    catch {
      console.log('Get Page Failed');
    }
  }  
  
  function getPaymentViewList(paymenthistoryid){
     return Axios.post(apiUrl+'product/paymentview', paymenthistoryid);
  }