import { SET_VIEWPRODUCTLIST } from '../actions';

const productlistview = (state = [], action) => {
  switch (action.type) {
	case SET_VIEWPRODUCTLIST:
      return [...action.value];  
    default: return state;
  }
}

export default productlistview;
