import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import { apiUrl,productURL,invoiceURL} from'../Config/Config';
import axios from 'axios';

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_PAYMENTLIST  } from '../../actions';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { confirmAlert } from 'react-confirm-alert';

//const current = new Date();
 // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

 var d = new Date(); d.setMonth(d.getMonth() - 1);
class List extends Component {


  
  constructor(props)
  {
    super(props);
    this.state = {
        paymenthistorylist:'',
        paymenttypelist:[],
        //start_date:'mm/dd/yyyy',
        //end_date:'mm/dd/yyyy',
        //start_date:d,
        start_date:new Date(),
        end_date:new Date()
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_type:'',
    start_date:'',
     end_date:'',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.props.getPaymentList(qs.stringify(postobject));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    }

   
    componentDidMount() {

     
   }

   handlePageChange(pageNumber) {

     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_type: this.state.search_type,
       admin_id: admin_id,
       loggedas: loggedas
     };
     this.props.getPaymentList(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
   //var startd = formPayload.start_date; startd.setMonth(startd.getDate() - 1);
   //var endd = formPayload.end_date; endd.setMonth(endd.getDate() + 1);
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_type: formPayload.search_type,
    start_date: formPayload.start_date,
    end_date: formPayload.end_date,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.props.getPaymentList(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.paymenthistorylist).length > 0) {
      if (Props.paymenthistorylist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.paymenthistorylist[0].records_count, paymenthistorylist: Props.paymenthistorylist[0]["paymenthistorylist"] });
               this.setState({ totalRecord_comp : Props.paymenthistorylist[0].records_count_comp, paymenthistorylist_comp: Props.paymenthistorylist[0]["paymenthistorylist_completed"] });

        this.paymenthistorylist();
      }
    }
     
   }

   handleClickSelecetType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    console.log(event.target.options);
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
  
    var postObject = {             
      activePage   : 1,
      search_type : key,
      admin_id: admin_id,
      loggedas: loggedas         
    };
  
    this.props.getPaymentList(qs.stringify(postObject));
  
    this.setState({
        Loading:true     
    })
  
  }


  onChangedatestart = (date) => { 

  this.setState({ start_date: date })
  }

  onChangedateend = (date) => { 

  this.setState({ end_date: date })
  }

    handlestatusupdateType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  var id = event.target.options[selectedIndex].getAttribute('id');
   


    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure, Do you want to change the order status ?</h1>

            <button
              onClick={() => {
                this.onClose_order();
                onClose();
              }}
            >
              No
            </button>
            <button
              onClick={() => {
                this.change_orderstatus(key, id);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
  
  }

onClose_order(){
     window.location.reload(); 
    }

   change_orderstatus(key, id){

     var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
      var postObject = {             
      activePage   : 1,
      change_status_order : key,
      admin_id: admin_id,
      payment_id: id,
    };
  console.log("postObject",postObject);
     axios.post(apiUrl+"product/order_change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
         setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        3000
        );
    }else if(res.data.status === 'error'){
      this.setState({  cardetailloading: false});
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
         setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        5000
        );
      }
     }); 
    }

	  onChangeHandler = (id,event) =>{
          let reader = new FileReader();
          const file = event.target.files[0];
          const filename = file.name;
          reader.onloadend = () => {
          this.setState({
            invoice_name: file.name,
            invoice_doc: file
          });
          };

          reader.readAsDataURL(file);

          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };

          var qs = require('qs');
            var postObject = {             
              invoice_name : file.name,
              invoice            : file,
              product_id         : id
          };

          let formData = new FormData();

          for(let k in postObject) {

          formData.append(k, postObject[k]);
          }
          
          axios.post(apiUrl+"product/upload_invoice",formData,config).then(res => {
          if(res.data.status === 'success'){
            $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
               setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
          }else if(res.data.status === 'error'){
            this.setState({  cardetailloading: false});
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
               setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              5000
              );
            }
           }); 
    }

    downloadinvoice(invoicedoc){
      let url =invoiceURL+"/"+invoicedoc;
      window.open(url,
      '_blank' // <- This is what makes it open in a new window.
      );
  }

   paymenthistorylist() {
    var paymenthistorylist = this.props.paymenthistorylist[0];
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      if (paymenthistorylist.status == "success") {
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.paymenthistorylist.map(
            (paymenthistorylist, Index) => {

              var current = this;

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var order_status = paymenthistorylist.order_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type;

            var username =  paymenthistorylist.username;
            var email = paymenthistorylist.email;
            var mobileno = paymenthistorylist.mobileno;

            var Product = paymenthistorylist.product_name == null ?  paymenthistorylist.product_name = 'N/A' : paymenthistorylist.product_name;
            var Category = paymenthistorylist.category_search_name == null ?  paymenthistorylist.category_search_name = 'N/A' : paymenthistorylist.category_search_name;
           
           

            var paymenttype;
            if(payment_type && payment_type!== null && payment_type!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype = String(payment_type).charAt(0).toUpperCase() + String(payment_type).slice(1);
              //payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

             let catImg = '';
             let preview = ''; 

            if (paymenthistorylist.product_image!== null && paymenthistorylist.product_image!== '') {
            catImg = productURL + "/" + paymenthistorylist.product_image;
          preview = <img className="img_class" src={catImg} alt="" />;
        }
           
         
          return (
                <tr key={paymenthistorylist.id}>
          <td>{sno}</td>
          <td>{Product } ({Category})</td>
          <td>{preview}</td>
          <td>{paymenthistorylist.product_quantity}</td>
          <td>{username}</td>
          <td>{mobileno}</td>
          <td>${paymenthistorylist.original_amount}</td>
          <td>{paid_status.charAt(0).toUpperCase() + paid_status.slice(1)}</td>
          <td>{payment_plan.charAt(0).toUpperCase() + payment_plan.slice(1)}</td>
           <td>
           <select className="prod-list-pay" onChange={current.handlestatusupdateType}  > 
                <option   data-key="">Search by order status</option>
                <option selected={order_status == "Booked"} id={paymenthistorylist.id} data-key="Booked">Booked</option>
                <option selected={order_status == "Assigned"} id={paymenthistorylist.id} data-key="Assigned">Assigned</option>
                <option selected={order_status == "Cancelled"} id={paymenthistorylist.id} data-key="Cancelled">Cancelled</option>
                <option selected={order_status == "Completed"} id={paymenthistorylist.id} data-key="Completed">Completed</option>
                </select>
                
            </td>
          <td>{moment(paymenthistorylist.created_date).format('MM/DD/YYYY')}</td>
          
                  <td className="actiongroup paymenthistorygrp">
                    <Link to={{ pathname: '/payment-view/'+paymenthistorylist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    {(order_status == 'Completed' && paymenthistorylist.order_invoice== null)?<><input id="fileupload" type="file" name="fileupload"  onChange={this.onChangeHandler.bind(this,paymenthistorylist.id)} /></>:''}
                    {(order_status == 'Completed' && paymenthistorylist.order_invoice !== null)?<>  <Link onClick={this.downloadinvoice.bind(this,paymenthistorylist.order_invoice)} className="" title="Export"><i className="fa fa-download" aria-hidden="true"></i></Link></>:''}
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="12" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="12" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }




   paymenthistorylist_completed() {
    var paymenthistorylist = this.props.paymenthistorylist[0];
   // console.log("paymenthistorylistpaymenthistorylist",paymenthistorylist);
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      if (paymenthistorylist.status == "success") {
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.paymenthistorylist_completed.map(
            (paymenthistorylist, Index) => {

              var current = this;

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var order_status = paymenthistorylist.order_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type == "onetime" ?  paymenthistorylist.payment_type = 'N/A' : paymenthistorylist.payment_type;

            var username =  paymenthistorylist.username;
            var email = paymenthistorylist.email;
            var mobileno = paymenthistorylist.mobileno;

            var Product = paymenthistorylist.product_name == null ?  paymenthistorylist.product_name = 'N/A' : paymenthistorylist.product_name;
            var Category = paymenthistorylist.category_search_name == null ?  paymenthistorylist.category_search_name = 'N/A' : paymenthistorylist.category_search_name;
           
           

            var paymenttype;
            if(payment_plan && payment_plan!== null && payment_plan!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype = String(payment_plan).charAt(0).toUpperCase() + String(payment_plan).slice(1);
              //payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

             let catImg = '';
             let preview = ''; 

            if (paymenthistorylist.product_image!== null && paymenthistorylist.product_image!== '') {
            catImg = productURL + "/" + paymenthistorylist.product_image;
          preview = <img className="img_class" src={catImg} alt="" />;
        }

        var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
          return (
              <tr key={paymenthistorylist.id}>
              <td>{sno}</td>
              <td>{Product } ({Category})</td>
              <td>{preview}</td>
              <td>{paymenthistorylist.product_quantity}</td>
              <td>{username}</td>
              <td>{mobileno}</td>
              <td>${paymenthistorylist.original_amount}</td>
              <td>{paid_status.charAt(0).toUpperCase() + paid_status.slice(1)}</td>
              <td>{payment_plan.charAt(0).toUpperCase() + payment_plan.slice(1)}</td>
              <td>{order_status.charAt(0).toUpperCase() + order_status.slice(1)}</td>
               
              <td>{moment(paymenthistorylist.created_date).format('MM/DD/YYYY')}</td>
              
              <td className="actiongroup">
                        <Link to={{ pathname: '/payment-view/'+paymenthistorylist.id+"/completed"}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                      </td>
                </tr>
                
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="13" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="13" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  
  


  render() {
    const {paymenttypelist,start_date,end_date} = this.state;
    var current = this;

    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
    //console.log("targetValue",lasturrl)

    return (
      <div className="wrapper"> 
 
    <Header />

   {(lasturrl ==='payment-completed-history')?
    <>
     <MenuSidebar currentpage="payment-completed-history" />
     </>:<>
     <MenuSidebar currentpage="payment-history" />
    </>
  }
      
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                {(lasturrl ==='payment-completed-history')?                  
                   <h3>Completed Orders</h3>:<h3>Order History</h3>                  
                }                 
                </div>

                {(lasturrl ==='payment-completed-history')?
                  <>         
                  </>:<>
					<div className="form-group">
						<select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
						<option data-key="">Search by order status</option>
						<option data-key="Booked">Booked</option>
						<option data-key="Assigned">Assigned</option>
						<option data-key="Cancelled">Cancelled</option>
						<option data-key="Completed">Completed</option>
						</select>
						<div className="errorassign_paymenttype"></div>
					</div>
                  </>
                }

                {/*(lasturrl ==='payment-completed-history')?
                  <>                   
                  </>:<>
                   <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>              
                </>
                */}
				
             </div> 
			 
			 
                
                <div className="search-list-date">
                <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >		
                    <div className="start-date">
						<label>Start Date : </label>
						<DatePicker
						name="Start Date"
                        selected={this.state.start_date}
                        onChange={this.onChangedatestart}
                        dateFormat="MM/dd/yyy"
                        showMonthDropdown
                        showYearDropdown
						value={this.state.start_date}
                        />
					</div>
					<div className="end-date">
                        <label>End Date : </label>
                        <DatePicker
                         name="End Date"
                        selected={this.state.end_date}
                        onChange={this.onChangedateend}
                        dateFormat="MM/dd/yyy"
                        showMonthDropdown
                        showYearDropdown
						value={this.state.end_date}
                        />                    
					</div>
					<button className="serach-btn" type="submit"><i class="fa fa-search"></i></button>
                 
                </form>
                <button className="refresh-btn" onClick={() => {this.onClose_order();}} type="submit"><i class="fa fa-refresh"></i></button> 
                </div>
                
             

            <div className="success_message"></div>

              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
                  <th>Product</th>
                  <th>Image</th>
                  <th> Quantity</th>
			            <th>User Name</th>
                  <th>User Contact</th>
                  <th> Amount</th>
                   <th>Payment Status</th>
                   <th>Payment Type</th>
                   <th>Order Status</th>
                   <th>Ordered on</th>
                  <th>Action</th>
                   </tr>
                  </thead>

                  {(lasturrl ==='payment-completed-history')?
                  <>
                   <tbody>{this.paymenthistorylist_completed()}</tbody>
                   </>:<>
                   <tbody>{this.paymenthistorylist()}</tbody>
                  </>
                }

                  
                </Table>
                </div>

                {(lasturrl ==='payment-completed-history')?
                  <>
                   <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord_comp}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                   </>:<>
                   <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                  </>
                }
                
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    paymenthistorylist: state.paymenthistorylist,
     paymenthistorylist_comp: state.paymenthistorylist_comp
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentList: (formVal) =>{
      dispatch({ type: GET_PAYMENTLIST, formVal});
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));