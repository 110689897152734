import { SET_PAYMENTLIST } from '../actions';

const paymenthistorylist = (state = [], action) => {
  switch (action.type) {
	case SET_PAYMENTLIST:
      return [...action.value];
    default: return state;
  }
}

export default paymenthistorylist;
