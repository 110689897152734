import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_FORMENTRYDETAIL } from '../../actions';
import { apiUrl, carImageUrl, currency, dummycarUrl,formentriesURL} from'../Config/Config';
import moment from 'moment';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const entriesid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      entriesid: entriesid,
      entriesview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :entriesid
    };
    this.props.getFormEntryDetail(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('Entries View');
   }

   componentWillReceiveProps(Props){
      if(Props.entriesview!==this.state.entriesview){
        if(Props.entriesview[0].status == "success"){
          this.setState({entriesview: Props.entriesview[0].entrieslist}) 
        }
      }
   }
  
  formentries(){
    const entriesview = this.state.entriesview;
    if(entriesview!== undefined && entriesview!== null){
            if(Object.keys(entriesview).length > 0) {
              const Details = entriesview.entries.map((datas, index) =>{
                                return(
                                  <div className="details-view-full">
                                      <div className="details-view-left">
                                         {datas.name}<span>:</span>
                                      </div>
                                      <div className="details-view-right">
                                        <p>{decodeURIComponent(datas.value)}</p>
                                      </div>
                                      {

                                      }
                                  </div>
                                );
                              })
               return Details;
          }

 }                
}
  render() {
    return (
      <div className="wrapper"> 
      <Header />

    <MenuSidebar currentpage="form-entries" />  
      <div className="content"> 
    <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="listing-header">
                      <div className="title">
                            <h4>Form Details</h4>
                      </div>
                      <div className="add_button">  
                          <a className="btn btn_orange btn_minwid animate-btn2" href='/formentries'>Back</a> 
                      </div>  
           </div>
           
            <div className="form-row">
              <div className="form-left form-entries">
                  <div className="form-entries-main">
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Form name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.form_name}</p>
                          </div>
                  </div>
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.category}</p>
                          </div>
                  </div>
          
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Submitted by<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.username?this.state.entriesview.username:'Guest User'}</p>
                          </div>
                  </div>
          
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Submitted on<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{moment(this.state.entriesview.submitted_on).format('MM/DD/YYYY')}</p>
                          </div>
                  </div>
                   </div>
                  <div className="details-view entries-details-view">
                    <span><h3>Entries</h3></span>
                      {this.formentries()}
                 </div>
              </div>
              {this.state.entriesview.images!==''?<div className="form-right">
                  <div className="details-view entries-details-view">
                    <span><h3>Form Image:</h3></span>
                      <img src={formentriesURL+'/'+this.state.entriesview.images} />
                 </div>
              </div>:''}
            </div>
          </div>  
      </div>        
    </div>  
  </div>      
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    entriesview: state.entriesview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormEntryDetail: (entriesID) =>{
       dispatch({ type: GET_FORMENTRYDETAIL,entriesID });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));