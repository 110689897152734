import { SET_PRODUCTLIST } from '../actions';

const productlist = (state = [], action) => {
  switch (action.type) {
	case SET_PRODUCTLIST:
      return [...action.value];  
    default: return state;
  }
}

export default productlist;
