import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { cmscontentURL } from '../Config/Config';

import { GET_FORMENTRIESLIST } from '../../actions';
var Parser = require('html-react-parser');
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      entrieslist:'',
      totalRecord:0
    };

    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };

    this.props.getFormEntriesList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);


    }

   
   componentDidMount() {
      document.title = PageTitle('FormEntries List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

    handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      this.props.getFormList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : formPayload.search_all,
            admin_id: admin_id         
          };
          //console.log(postObject);
          this.props.getFormList(qs.stringify(postObject));
        
    } 


   componentWillReceiveProps(Props){
    if (Object.keys(Props.entrieslist).length > 0) {
      if (Props.entrieslist[0]["status"] === "success") {
         this.setState({ totalRecord : Props.entrieslist[0].records_count, entrieslist: Props.entrieslist[0] }, function() {
            this.entrieslist();
         });
      }
    }
	
	  if(Props.priorityupdate !==  this.props.priorityupdate){
        if (Props.priorityupdate[0]["status"] === "success") {
           this.props.getFormList();
        }
     }

      if(Props.formactive !== this.props.formactive){
      if (Props.formactive[0]["status"] === "success") {
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              this.props.getFormList(qs.stringify(postobject));

      }
    }

	
   }
   
activateuser(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };
      this.props.getActivateForm(qs.stringify(postObject));
}
  entrieslist() {

    var entrieslist = this.state.entrieslist;
    if (entrieslist != "undefined" && entrieslist != null) {
      if (entrieslist.status == "success") {
        if (Object.keys(entrieslist).length > 0) {
          const vehiclelistDetails = entrieslist.entrieslist.map(
            (entrieslist, Index) => {
              var sno = Index+1;
			        var istyle = { width: 100};
              return (
                <tr key={entrieslist.id}>
                    <td>{sno}</td>
                    <td>{entrieslist.category}</td>
                    <td>{entrieslist.form_name}</td>
                    <td>{entrieslist.username?entrieslist.username:'Guest User'}</td>
                      <td className="actiongroup">
                        <Link to={{ pathname: '/formentries-view/'+entrieslist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    </td>
                </tr>
              );
            }
          );
          return vehiclelistDetails;
        }
      } else {

        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );

      }
    }

  }
  
  render() {

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="form-entries" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Forms Entries</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Category Name</th>
                      <th>Form Name</th>
                      <th>User</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.entrieslist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    entrieslist: state.entrieslist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFormEntriesList: (formVal) =>{
       dispatch({ type: GET_FORMENTRIESLIST, formVal});
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));