import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const categoryId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            categoryId: categoryId,
			cat_name:'',
			description:'',
			short_description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image:'',
			image_name: '',
			image_preview: '',
		};
		// this.props.getCategoryDetailList(categoryId);
		var qs = require('qs');
		var catid = this.state.categoryId;

		axios.get(apiUrl+"ProductCategory/listdetail?id="+catid).then(res => {
			this.setState({image_preview:res.data.categorylist.image,cat_name:res.data.categorylist.name,description:res.data.categorylist.description,short_description:res.data.categorylist.short_description});
			if(res.data.categorylist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'cat_trending'){
      	this.setState({cat_trending_checked: !this.state.cat_trending_checked});
      }  		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}

	componentDidMount() {
		document.title = PageTitle('Category Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					cat_name: formPayload.cat_name,
					cat_description: formPayload.description,
					short_description:formPayload.short_description,	
					status:  status,
					id: formPayload.categoryId,
					image: formPayload.image
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"ProductCategory/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/ProductCategory'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {cat_name,description,short_description} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!cat_name) {
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			$('.errorcat_name').html('');
		}

		// if (!description) {
		// 	formIsValid = false;
		// 	$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		// }else if(description){
		// 	$('.errordescription').html('');
		// }

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	if(Props.categorydetails !== this.props.categorydetails){
   		
    		if(Object.keys(Props.categorydetails).length > 0){
				this.setState({Loading:false});

					if(Props.categorydetails[0].status === "success"){

						const formdata = Props.categorydetails[0].categorylist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						if( parseInt (formdata.form_builder_id) !==0 ){

							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}
		

						this.setState({image_preview:formdata.image});
						this.setState({cat_name:formdata.cat_name});
						this.setState({description: formdata.description});						
						this.setState({short_description: formdata.short_description});
                        this.setState({categoryId: formdata.id});


                        
					}
    		}
    	}

    	if(Props.categoryedit !== this.props.categoryedit){
    		if(Object.keys(Props.categoryedit).length > 0){
				if(Props.categoryedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.categoryedit[0].categorylist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
					}	
					this.setState({image_preview:formpayload.image});
					this.setState({cat_name:formpayload.cat_name});
					this.setState({description: formpayload.description});						
					this.setState({short_description: formpayload.short_description});
					this.setState({categoryId: formpayload.id});
			
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/category'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }


  render() {


  	  const {selectedOption} = this.state;

		let loaderimg = '';
		let preview = '';

		  const imagepre = categoryURL+'/'+this.state.image_preview;
		
		  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
			  loaderimg = categoryURL + '/'+this.state.image_preview;
			  preview = <img className="img_class" src={loaderimg} alt="" />;
			// alert('hh');
		  }else{
			  preview = <img className="img_class" src={imagepre} alt="" />;
		  }


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="ProductCategory" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Product Category Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>

				<div className="form-group">					
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
					<div className="errorshort_description"></div>
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div>
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (categoryId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,categoryId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));