import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,guideURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const guide_id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      guide_id: guide_id,
      guidelistview:'',
      selectedtemplate_name:'',
      fileExtension:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :guide_id
    };

    axios.post(apiUrl+"Guide/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ guidelistview: res.data.Guidelistview,selectedtemplate_name:res.data.selectedtemplate_name,fileExtension:res.data.ext});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Category View');
   }
  render() {
  if(this.state.guidelistview!==undefined){
    var guidelistview = this.state.guidelistview;
  }
  let loaderimg = '';
  let preview = '';

  // const imagepre = guideURL+'/'+guidelistview.image;
		
  // if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
  //   loaderimg = guideURL + '/'+guidelistview.image;
  //   preview = <img className="img_class" src={loaderimg} alt="" />;
  // // alert('hh');
  // }else{
  //   preview = <img className="img_class" src={imagepre} alt="" />;
  // }
  if(this.state.fileExtension === ('jpg' || 'jpeg' || 'png')){

    loaderimg = guideURL + '/'+guidelistview.image;
    preview = <img className="img_class" src={loaderimg} alt="" />;
  }else if((this.state.fileExtension ==='docs' ||'pdf' ||'txt')){
  
    
    loaderimg = guideURL + '/'+guidelistview.image;
    preview =  <a href = {loaderimg} target = "_blank">Download {this.state.fileExtension}</a>;
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Guide" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Guide Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Guide Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{guidelistview.name}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{guidelistview.description}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{guidelistview.short_description}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(guidelistview.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Template Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(this.state.selectedtemplate_name!='')?this.state.selectedtemplate_name:'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Upload Files<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          {preview}
                        </div>
                      </div>
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylistview: state.categorylistview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewCategoryList: (guide_id) =>{
       dispatch({ type: GET_VIEWCATEGORYLIST,guide_id });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));