import { SET_SETTINGDATA, SET_PLUGINCONTENT, SET_UPDATEPLUGINCONTENT } from '../actions';

export const settings = (state = [], action) => {
  switch (action.type) {
	case SET_SETTINGDATA:
      return [...action.value];  
    default: return state;
  }
}



export const plugincontent = (state = [], action) => {
  switch (action.type) {
	case SET_PLUGINCONTENT:
      return [...action.value];  
    default: return state;
  }
}



export const updateplugincontent = (state = [], action) => {
  switch (action.type) {
	case SET_UPDATEPLUGINCONTENT:
      return [...action.value];  
    default: return state;
  }
}


