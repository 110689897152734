import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  }  from 'react-places-autocomplete';


import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions'; 

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			userid: userid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
			user_confirm_password: '',
			 new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			domain_value:'',
			domainlist:[],	
			location:'',
			same_as_billing_checked:true,
			same_as_billing:'on',
			shipping_street:'',
			shipping_city:'',
			shipping_state:'',
			shipping_country:'',
			shipping_postalcode:'',
			billing_street:'',
			billing_city:'',
			billing_state:'',
			billing_country:'',
			billing_postalcode:'',	
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.props.getUserDetailList(userid);
	   var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'user/getdomainlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
					})
				
				}else{
				//console.log("test")
				}
			});
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      /*if(name == 'password'){
      	this.setState({disablepasssword: false})
      }*/
	  if(name == 'same_as_billing'){
		
		this.setState({same_as_billing_checked: !this.state.same_as_billing_checked})
	  }
	  if(this.state.same_as_billing_checked===true){
			
		this.setState({
			shipping_street: this.state.billing_street,
			shipping_city: this.state.billing_city,
			shipping_state: this.state.billing_state,
			shipping_country: this.state.billing_country,
			shipping_postalcode: this.state.billing_postalcode,
		
		})

	

}
    }
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var shipping_as_billing = 0;
		if(this.state.same_as_billing_checked == true){
			var shipping_as_billing = 1;
		}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id : formPayload.domain_id,

					username     : formPayload.username,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					location      : formPayload.location,
					/*currentpswd  : formPayload.password,*/
					changepassword:formPayload.changepassword, 
					status       :  status,
					shipping_street:formPayload.shipping_street, 
					shipping_city:formPayload.shipping_city, 
					shipping_state:formPayload.shipping_state, 
					shipping_country:formPayload.shipping_country, 
					shipping_postalcode:formPayload.shipping_postalcode, 
					billing_street:formPayload.billing_street, 
					billing_city:formPayload.billing_city, 
					billing_state:formPayload.billing_state, 
					billing_country:formPayload.billing_country, 
					billing_postalcode:formPayload.billing_postalcode,
					shipping_as_billing:shipping_as_billing,
				};
				this.props.getEditUser(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, changepassword, user_confirm_password,domain_id,location} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		// if (!username) {
		// 	errors++;
		// 	$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		// }else if(username){
		// 	$('.errorusername').html('');
		// }

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			$('.errorfirstname').html('');
		}
		// if (!domain_id) {
		// 	errors++;
		// 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		// }else if(domain_id){
		// 	$('.errorassign_domain').html('');
		// }
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
			// if (!phoneno) {
       
			// 	errors++;
			//   $('.errorphoneno').html('<span class="errorspan">Phone number is required</span>');
		
			// }else if(phoneno){	
			//    var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			//    if (pattern.test(phoneno)) {
		  
			// 		   $('.errorphoneno').html('');
			// 		 }else{
			// 		   errors++;
			// 		   $('.errorphoneno').html('<span class="errorspan">Please enter valid mobile number</span>');
			// 		 }	
			// 		}
			if (!phoneno) {
				errors++;
				
				$('.errorphoneno').html('<span class="errorspan">Phone number is required</span>');
			  }else if(phoneno){	
				 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
				 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
					if (pattern.test(phoneno)) {
					$('.errorphoneno').html('');
					}else if(indiapattern.test(phoneno)){
					$('.errorphoneno').html('');
					}else{
						 errors++;
					
						 $('.errorphoneno').html('<span class="errorspan">Please enter valid phone number</span>');
					   }
					}
					if (!location) {
						errors++;
						$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
					}else if(location){
						$('.errorlocation').html('');
					}
		if(changepassword !=''){
		 if (changepassword.match(/[a-z]/g) && changepassword.match( 
                    /[A-Z]/g) && changepassword.match( 
                    /[0-9]/g) && changepassword.match( 
                    /[^a-zA-Z\d]/g) && changepassword.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	errors++;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		
		if(user_confirm_password !=''){
		 if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
                    /[A-Z]/g) && user_confirm_password.match( 
                    /[0-9]/g) && user_confirm_password.match( 
                    /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		 
		if(changepassword !== user_confirm_password){
          	errors++;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }
		
		}
		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
	handleClickSelecet = (event) => {
		
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
    componentWillReceiveProps(Props){
    	   if(Props.userdetails !== this.props.userdetails){
    		if(Object.keys(Props.userdetails).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.userdetails[0].status === "success"){

						const formdata = Props.userdetails[0].userlist;

						if(Props.userdetails[0].userlistaddress!=null){
							var shipping_as_billing = false;
		
							const formdataaddres = Props.userdetails[0].userlistaddress;
							if(formdataaddres.shipping_as_billing == 1){
								var shipping_as_billing = true;
							}
							this.setState({billing_street: formdataaddres.street_address});
							this.setState({billing_country: formdataaddres.country});
							this.setState({billing_state: formdataaddres.state});
							this.setState({billing_city: formdataaddres.city});
							this.setState({billing_postalcode: formdataaddres.pincode});
							
							this.setState({shipping_street: formdataaddres.shipping_street});
							this.setState({shipping_city: formdataaddres.shipping_city});
							this.setState({shipping_state: formdataaddres.shipping_state});
							this.setState({shipping_country: formdataaddres.shipping_country});
							this.setState({shipping_postalcode: formdataaddres.shipping_postalcode});
	
							this.setState({same_as_billing_checked: shipping_as_billing});
						}
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						
						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobileno});
						this.setState({userid: formdata.id});
						this.setState({domain_id: formdata.domain_id});
						this.setState({location: formdata.location});
						

					}
    		}
    	}

	
         if(Props.useredit !== this.props.useredit){
    		if(Object.keys(Props.useredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.useredit[0].status === "success"){

    			const formdata = Props.useredit[0].userlist;
				
				console.log(formdata);
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}


				if(Props.userdetails[0].userlistaddress!=null){
					
					const formdataaddres = Props.userdetails[0].userlistaddress;
					var shipping_as_billing = false;
if(formdataaddres.shipping_as_billing == 1){
	var shipping_as_billing = true;
}
					this.setState({billing_street: formdataaddres.street_address});
					this.setState({billing_country: formdataaddres.country});
					this.setState({billing_state: formdataaddres.state});
					this.setState({billing_city: formdataaddres.city});
					this.setState({billing_postalcode: formdataaddres.pincode});
					
					this.setState({shipping_street: formdataaddres.shipping_street});
					this.setState({shipping_city: formdataaddres.shipping_city});
					this.setState({shipping_state: formdataaddres.shipping_state});
					this.setState({shipping_country: formdataaddres.shipping_country});
					this.setState({shipping_postalcode: formdataaddres.shipping_postalcode});

					this.setState({same_as_billing_checked: shipping_as_billing});
				}else{
					var shipping_as_billing = true;
					this.setState({same_as_billing_checked: shipping_as_billing});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({userid: formdata.id});
				this.setState({location: formdata.location});
				

                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/user'));
						}
						.bind(this),
						3000
						);
    			}else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}   
      }
	  handleChangeaddress = location => {
		this.setState({ location });
	  };
	 
	  handleSelect = location => {

		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));


		  this.setState({ location });
		//   console.log(results,'results[0]')
	  };



	
  render() {

  	const {selectedOption,domainlist,domain_id} = this.state;
	  var current = this;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="user" />  	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	{/* <div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div> */}
			<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				<div className="form-group eye-pad">
                    <label>Reset Password<span class="required" style={{ color: "red" }} > * </span></label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>
				  <div className="default-font">
				
				</div>
				{/*<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.chnagepassword}/>
					<div className="errorchangepaswd"></div>
				</div>*/}
				<div className="form-group">
						<label>Location<span class="required" style={{ color: "red" }} > * </span>:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					    </div>
			
				
				
				   
				</div>
			<div className="form-right">
				
				<div className="form-group">
					<label>Last Name<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Phone number<span class="required" style={{ color: "red" }} > * </span>:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				{localStorage.getItem('admin_id')!=1 ? <div className="form-group eye-pad">
                    <label>Confirm Password<span class="required" style={{ color: "red" }} > * </span></label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                  </div>:null}
                   <div className="errorchangepaswd"></div>
				{/* <div className="form-group">					
					<label>Assign Domain : </label>
					<select  onChange={current.handleClickSelecet} >                    
        {this.state.domainlist.map((domainlist) => <option key={domainlist.value} data-key={domainlist.value} value={domainlist.value} selected={domainlist.value === domain_id}>{domainlist.label} </option>)}
            </select>
						
					<div className="errorassign_domain"></div>
	</div> */}
	

				
						<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span>:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			
				</div>
			</div>		
			<div className="title">
					<h4>Billing Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street:</label>
				    <input type="text" name="billing_street" onChange={this.handleInputChange} className="form-control" value={this.state.billing_street} />
					<div className="errorbilling_street"></div>
				</div>
				<div className="form-group">
						<label>State:</label>
						<input type="text" name="billing_state" onChange={this.handleInputChange} className="form-control" value={this.state.billing_state} />
						<div className="errorbilling_state"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code:</label>
				    <input type="text" name="billing_postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.billing_postalcode} />
					<div className="errorbilling_postalcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City:</label>
				    <input type="text" name="billing_city" onChange={this.handleInputChange} className="form-control" value={this.state.billing_city} />
					<div className="errorbilling_city"></div>
				</div>
				<div className="form-group">
					<label>Country:</label>
				    <input type="text" name="billing_country" onChange={this.handleInputChange} className="form-control" value={this.state.billing_country} />
					<div className="errorbilling_country"></div>
				</div> 
					
				</div>

			</div>

			
			<div className="form-group">
							<input onChange={this.handleInputChange} type="checkbox"  name="same_as_billing" checked={this.state.same_as_billing_checked}/>
							<label>Shipping Address same as billing</label>
					</div>
			{this.state.same_as_billing_checked===false ?<div className="title">
					<h4>Shipping Address</h4>
			</div>:null}

			{this.state.same_as_billing_checked===false ? <div className="form-row">
				<div className="form-left">
	
					<div className="form-group">
						<label>Street:</label>
						<input type="text" name="shipping_street" onChange={this.handleInputChange} className="form-control" value={this.state.shipping_street} />
						<div className="errorshipping_street"></div>
					</div>
					
					<div className="form-group">
						<label>State:</label>
						<input type="text" name="shipping_state" onChange={this.handleInputChange} className="form-control" value={this.state.shipping_state} />
						<div className="errorshipping_state"></div>
					</div>
					   
					<div className="form-group">
						<label>Postal Code:</label>
						<input type="text" name="shipping_postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.shipping_postalcode} />
						<div className="errorshipping_postalcode"></div>
					</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City:</label>
				    <input type="text" name="shipping_city" onChange={this.handleInputChange} className="form-control" value={this.state.shipping_city} />
					<div className="errorshipping_city"></div>
				</div>
				<div className="form-group">
						<label>Country:</label>
						<input type="text" name="shipping_country" onChange={this.handleInputChange} className="form-control" value={this.state.shipping_country} />
						<div className="errorshipping_country"></div>
					</div>
					
				</div>

			</div>:null}
			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	userdetails    : state.userdetails,
  	useredit     : state.useredit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	 getUserDetailList: (userid) =>{
       dispatch({ type: GET_USERDETAILLIST,userid });
    },
    getEditUser: (formPayload) =>{
       dispatch({ type: GET_EDITUSER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));