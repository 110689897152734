/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_COUPONLIST, SET_COUPONLIST, GET_COUPONDETAILLIST, SET_COUPONDETAILLIST, GET_ADDCOUPON, SET_ADDCOUPON,GET_EDITCOUPON, SET_EDITCOUPON} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*list*/



export const watchGetCouponList = function* () {
  yield takeEvery(GET_COUPONLIST, workerGetCouponList);
}

function* workerGetCouponList({ formVal }) {
  try {
      
 const result = yield call(getCouponList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_COUPONLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getCouponList(formVal) {
   return Axios.post(apiUrl+'coupon/list', formVal);
} 





/*get user details*/ 

export const watchGetCouponDetailList = function* () {
  yield takeEvery(GET_COUPONDETAILLIST, workerGetCouponDetailList);
}

function* workerGetCouponDetailList({ couponId }) {

    var url = apiUrl+'coupon/listdetail?id='+couponId;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_COUPONDETAILLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}

/*save the coupon*/

export const watchGetAddCoupon = function* () {
  yield takeEvery(GET_ADDCOUPON, workerGetAddCoupon);
}

function* workerGetAddCoupon({ formPayload }) {
  try {
      
 const result = yield call(getAddCoupon, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCOUPON, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddCoupon(formPayload) {
   return Axios.post(apiUrl+'coupon/add', formPayload);
} 


/*Edit the coupon*/

export const watchGetEditCoupon = function* () {
  yield takeEvery(GET_EDITCOUPON, workerGetEditCoupon);
}

function* workerGetEditCoupon({ formPayload }) {
  try {
      
 const result = yield call(getEditCoupon, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCOUPON, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditCoupon(formPayload) {
   return Axios.post(apiUrl+'coupon/edit', formPayload);
} 

