import { SET_DELETEPRODUCT } from '../actions';

const deleteproduct = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEPRODUCT:
      return [...action.value];  
    default: return state;
  }
}

export default deleteproduct;
