import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import {  apiUrl,doctorURL } from '../Config/Config';

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  }  from 'react-places-autocomplete';

class Edit extends Component {

	constructor(props)
	{
		super(props);	
		const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			id: id,
			name:'',
			email:'',
			phoneno:'',
			status:'',
			description: '',
			selectedDomain:'',
			domainlist:[],
			specialist:[],
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			location:''
			
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	  
	   this.handleChange = this.handleChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		if(name == 'dont_distrub'){
			this.setState({dont_distrub_checked: !this.state.dont_distrub_checked})
		}
		if(name == 'certificate_verified'){
			this.setState({certificate_verified_checked:!this.state.certificate_verified_checked})
		}


    }
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}

		axios.get(apiUrl+'location/listdetail?id='+this.state.id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
					const formdata = res.data.locationlist;
				
					
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'},selectedvalue:'active'});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'},selectedvalue:'inactive'});
					}

						

					
					this.setState({location: formdata.location});			
		


					

		
				}else{
			
			}
		});

		
		
    }


	handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			var qs = require('qs');
			const formPayload = this.state;
			var status = '';
			var dontditrub = 0;
			var certificate = 'NO';
			if(this.state.dont_distrub_checked == true){
				var dontditrub = 1;
			}
			if(this.state.certificate_verified_checked == true){
				certificate ='Yes';
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				id: formPayload.id,
				
				location: formPayload.location,
				
				status       :  formPayload.selectedvalue,
			};
			let formData = new FormData();
			for(let k in postObject) {
				formData.append(k, postObject[k]);
			}
			axios.post(apiUrl+"location/edit",formData).then(res => {

				if(res.data.status === "success"){	

					const formdata = res.data.locationlist;
					
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'},selectedvalue:'active'});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'},selectedvalue:'inactive'});
					}
					this.setState({location: formdata.location});	
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
							setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/Location'));
								}
								.bind(this),
								1000
								);
						}
						.bind(this),
						3000
						);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							5000
							);
				}
				this.setState({Loading:false});
			});

		}
}

	validateForm() {
		const {location} = this.state;
		let errors = 0;



		if (!location) {
			errors++;
			$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
		}else if(location){
			$('.errorlocation').html('');
		}

		
		
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

	  handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				domain_id: key,
				
		})


	}

	handlespecialist = (event) => {


		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		console.log(key,'specialist_id')
		this.setState({
			specialist_id: key,
				
		})
	}
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	 
	  handleSelect = location => {

		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));


		  this.setState({ location });
		//   console.log(results,'results[0]')
	  };

  render() {
  	const {preview1, name_img1,selectedOption,specialist,domainlist,image2} = this.state;
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="doctors" />  	
	<div className="content">	
		<div className="content-wrapper">
		         <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			 <div className="form-row">
				<div className="form-left">
				<div className="form-group">
						<label>Location:</label>
							<input type="text" name="location" onChange={this.handleInputChange} className="form-control" value={this.state.location} autoComplete="off" placeholder='Location'/>
							
						
							<div className="errorlocation"></div>
					    </div>
					
				   
				</div>
				<div className="form-right">
					 
					
						

						<div className="default-font">
							<div className="form-group">					
								<label>Status:</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
										options={lang.common.status_option} 
										onChange={this.handleChange}
										/>
							</div>
						</div>
						
						
					</div>
				</div>

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true ?
                                        <span>Loading</span>:<span>Submit</span> 
                                        }
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(Edit));