import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,specialistURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CKEditor from 'ckeditor4-react';


class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const categoryId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            categoryId: categoryId,
			cat_name:'',
			description:'',
			short_description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedModule:{ value: 'module1', label: 'Module1 (Vets Listing)' },
			selectedmodulevalue:'',
			image:'',
			image_name: '',
			image_preview: '',
			selectedcategoryvalue:'',
			productcategorynamelist:[],
			category_name:'',
			selectedcategoryid:'',
			title_description:'',
			tabgalleries: '',
			selectid:'',
			imagepath:'',
			selected_image:'',
			tab_image:'',
			button_text:'',
		};
		var qs = require('qs');
		var catid = this.state.categoryId;

		axios.get(apiUrl+"specialist/listdetail?id="+catid).then(res => {
			this.setState({image_name:res.data.categorylist.image,cat_name:res.data.categorylist.cat_name,description:res.data.categorylist.description,short_description:res.data.categorylist.short_description});
			if( res.data.categorylist.title_description !== 'null'){
				this.setState({title_description: res.data.categorylist.title_description})
			}else{
				this.setState({title_description: ''})
			}
			if(res.data.categorylist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.categorylist.module === 'module1'){
				$('.category-section').hide();
				this.setState({selectedModule:{value: 'module1', label: 'Module1 (Vets Listing)'}});
			}else if(res.data.categorylist.module === 'module2'){
			this.setState({selectedModule:{value: 'module2', label: 'Module2 (Product Listing)'}});
			}else if(res.data.categorylist.module === 'module4'){
				this.setState({selectedModule: { value: 'module4', label: 'Module4 (Ebag Products)' }});
			}
			else if(res.data.categorylist.module === 'book'){
				$('.category-section').hide();
				this.setState({selectedModule:{value: 'book', label: 'Book a Home Visit'}});
				}
			if(res.data.categorylist.product_category_id!=null && res.data.categorylist.product_category_id!=''){
			this.setState({selectedcategoryvalue:res.data.categorylist.product_category_id});
			this.setState({selectedcategoryid:res.data.categorylist.product_category_id});
			}
			this.setState({button_text:res.data.categorylist.button_text});
			this.setState({ tabgalleries: res.data.tabimageslist }, () => {
				console.log(this.state, 'productedit');
				// Render your galleries here using this.state.tabgalleries
			});
		}); 

		
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'specialist/getproductcategorynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						productcategorynamelist:res.data.productcategorynamelist
					});
				
				}else{
				}
			});

			


		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange1 = this.onEditorChange1.bind( this );
		this.onEditorChange = this.onEditorChange.bind( this );

    }
	componentDidMount() {
	

		document.title = PageTitle('Category Edit');
    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChangeModule = selectedModule => {

        this.setState({selectedModule})
        this.setState({  selectedmodulevalue : selectedModule.value});
		if(selectedModule.value == "module2")
		{
			$('.category-section').show();
		}else{
			this.setState({category_name: ''});
			$('.category-section').hide();
		}
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'cat_trending'){
      	this.setState({cat_trending_checked: !this.state.cat_trending_checked});
      }  		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name1: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}

	handleClickSelecetCategory = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
	
		this.setState({
				category_name: keys,	
		});
	}

	
	handleClickSelecetCategory = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');
	
		this.setState({
				category_name: keys,	
		});
	
	}

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 

				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var module = '';
				var category_name ="";
				var selectedcategorys='';

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.selectedmodulevalue === '' && formPayload.selectedmodulevalue!== 0){
					module =formPayload.selectedModule.value;
				}else{
					module = formPayload.selectedmodulevalue;
				}

				const selectedcategoryids = formPayload.selectedcategoryid;
				var elements=[];
				let catList  = ''
				if(typeof selectedcategoryids === 'object' && selectedcategoryids!=='' && typeof selectedcategoryids!==undefined && selectedcategoryids!==null) {
					catList = selectedcategoryids.map((number) =>
					elements.push(number.value)
				);
				selectedcategorys=elements.toString();

				}else{
					selectedcategorys=selectedcategoryids;

				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					module: module,
					product_category_id:selectedcategorys,
					name: formPayload.cat_name,
					description: formPayload.description,
					short_description:formPayload.short_description,	
					title_description: formPayload.title_description,
					status:  status,
					id: formPayload.categoryId,
					image: formPayload.image,
					button_text: formPayload.button_text,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				
				var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					} 
				axios.post(apiUrl+"specialist/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/category'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {cat_name,selectedcategoryid,description,short_description,selectedmodulevalue,category_name} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!cat_name) {
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			$('.errorcat_name').html('');
		}

		// if (!description) {
		// 	formIsValid = false;
		// 	$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		// }else if(description){
		// 	$('.errordescription').html('');
		// }

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		if(selectedmodulevalue == 'module2'){
			if(!selectedcategoryid){
			formIsValid = false;
			$('.errorproductcategory').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorproductcategory').html('');
			}
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');

	this.setState({
			category_name: keys,	
	});

}
handleProductCategoryChange = (event) => {  
	console.log(event,'onchange')
	this.setState({selectedcategoryid : event}, function() {
	   const user_list = this.state.selectedcategoryid;
	});
}

    componentWillReceiveProps(Props){


   	if(Props.categorydetails !== this.props.categorydetails){
   		
    		if(Object.keys(Props.categorydetails).length > 0){
				this.setState({Loading:false});

					if(Props.categorydetails[0].status === "success"){
						const formdata = Props.categorydetails[0].categorylist;
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}

						if(formdata.module === 'module1'){
							this.setState({selectedModule:{value: 'module1', label: 'Module1 (Vets Listing)'}});
						}else if(formdata.module === 'module2'){
							this.setState({selectedModule:{value: 'module2', label: 'Module2 (Product Listing)'}});
						}else if(formdata.module === 'book'){
							this.setState({selectedModule:{value: 'book', label: 'Book a Home Visit'}});
						}else if(formdata.module === 'module4'){
							this.setState({selectedModule: { value: 'module4', label: 'Module4 (Ebag Products)' }});
						}
				
						this.setState({image_name:formdata.image});
						this.setState({cat_name:formdata.cat_name});
						this.setState({description: formdata.description});						
						this.setState({short_description: formdata.short_description});
                        this.setState({categoryId: formdata.id,selectedcategoryvalue:formdata.product_category_id});
						

						if( formdata.title_description !== 'null'){
							this.setState({title_description: formdata.title_description})
						}else{
							this.setState({title_description: ''})
						}

                        // this.setState({categoryId: formdata.id,selectedcategoryvalue:formdata.product_category_id});
						

						// var myArray = formdata.product_category_id.split(',');
						// console.log(formdata.product_category_id,'formdata.product_category_id')
						// console.log(myArray,'myArray')

						// if(Object.keys(myArray).length > 0){
						// 	const selectedcategoryid = [];
						// 	myArray.map(function(item){  
						// 		selectedcategoryid.push({
						// 	value: item[0].value,
						// 	label: item[0].label
						// 	});
						// 	});
						// 	this.setState({selectedcategoryid:selectedcategoryid});	
						// }

                        
					}
    		}
    	}

    	if(Props.categoryedit !== this.props.categoryedit){
    		if(Object.keys(Props.categoryedit).length > 0){
				if(Props.categoryedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.categoryedit[0].categorylist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
					}	
					if(formpayload.module === 'module1'){
						this.setState({selectedModule:{value: 'module1', label: 'Module1 (Vets Listing)'}});
					}else if(formpayload.module === 'module2'){
						this.setState({selectedModule:{value: 'module2', label: 'Module2 (Product Listing)'}});
					}else if(formpayload.module === 'book'){
						this.setState({selectedModule:{value: 'book', label: 'Book a Home Visit'}});
					}else if(formpayload.module === 'module4'){
						this.setState({selectedModule: { value: 'module4', label: 'Module4 (Ebag Products)' }});
					}
			

					this.setState({image_name:formpayload.image});
					this.setState({cat_name:formpayload.cat_name});
					this.setState({description: formpayload.description});						
					this.setState({short_description: formpayload.short_description});

					
					if( formpayload.title_description !== 'null'){
						this.setState({title_description: formpayload.title_description})
					}else{
						this.setState({title_description: ''})
					}

					this.setState({categoryId: formpayload.id});
			
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/category'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
    }
	handleProductCategoryChange = (event) => {  
		this.setState({selectedcategoryid : event}, function() {
		   const user_list = this.state.selectedcategoryid;
		});
   }
   imageRemove = (Id) => {

	var postObject = {
		id:Id,
	};
	var qs = require('qs');

	axios.post(apiUrl+"specialist/deleteimage",qs.stringify(postObject) ).then(res => {
		if(res.data.status === "success"){	
			this.setState({image_name:res.data.categorylist.image,cat_name:res.data.categorylist.cat_name,description:res.data.categorylist.description,short_description:res.data.categorylist.short_description, title_description: res.data.categorylist.title_description});
			if(res.data.categorylist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.categorylist.module === 'module1'){
				$('.category-section').hide();
				this.setState({selectedModule:{value: 'module1', label: 'Module1 (Doctor Listing)'}});
			}else if(res.data.categorylist.module === 'module2'){
			this.setState({selectedModule:{value: 'module2', label: 'Module2 (Product Listing)'}});
			}else if(res.data.categorylist.module === 'modul4'){
				this.setState({selectedModule:{value: 'module4', label: 'Module4 (Ebag Products)'}});
			}
			
			else if(res.data.categorylist.module === 'book'){
				$('.category-section').hide();
				this.setState({selectedModule:{value: 'book', label: 'Book a Home Visit'}});
				}
		
			if(res.data.categorylist.product_category_id!=null && res.data.categorylist.product_category_id!=''){
				this.setState({selectedcategoryvalue:res.data.categorylist.product_category_id});			
				this.setState({selectedcategoryid:res.data.categorylist.product_category_id});
			}
		}
	});
    }

onEditorChange1( evt ) {
	// console.log(evt.editor.getData(), 'CKE')
	var callfunc = this;
	setTimeout(function () {
	callfunc.setState( {
		description: evt.editor.getData()
	} );
	}, 1000);
 
}

onEditorChange( evt ) {
	// console.log(evt.editor.getData(), 'CKE')
	var callfunc = this;
	setTimeout(function () {
	callfunc.setState( {
		short_description: evt.editor.getData()
	} );
	}, 1000);
 
}

confirmPopup = (id,imagepath,selected_image,type) => {
	this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
	$('.confirm-action').addClass('show');		
}
deleteimage(){
	var qs = require('qs');
	var postObject = {
	  admin_id : localStorage.getItem("admin_id"),
	  gallery_id  :this.state.selectid,
	  id  :this.state.tabId,
	  imagepath  :this.state.imagepath,
	  selected_image  :this.state.selected_image,
	  type: this.state.type
	};
	axios.post(apiUrl+"tabmanagement/deleteimage",qs.stringify(postObject)).then(res => {      
		  if(res.data.status === 'success'){
			if(this.state.type == 'product'){
				this.setState({productgalleries:res.data.productgalleries})
			}else{
				this.setState({tryoutgalleries:res.data.tryoutgalleries})
			}
			this.setState({ tabgalleries: res.data.productgalleries }, () => {
				// Render your galleries here using this.state.tabgalleries
			});
			$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
			setTimeout(function() {
				$('.success_message').html('');   
			}.bind(this),3000);
		  }
	}); 
}
Confirmstatus = (confirmstatus) => {
	if(confirmstatus == 'yes'){
	  this.deleteimage();
	  $('.confirm-action').removeClass('show');
	}else{
	  $('.confirm-action').removeClass('show');	
	}  
}
uploadMultipleFiles = (e) =>{
	this.fileObj = [];

		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i]
			this.imageArray.push(this.fileObj[0][i])

		}
		
		this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	removeImagebyindex = (indexvalue) => {
	   
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);

		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ tab_image: this.imageArray })	
  }
  
  render() {
	  const {selectedOption,selectedModule,productcategorynamelist,selectedcategoryvalue} = this.state;
  	
	  let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
		let loaderimg = '';
		let preview = '';

		  const imagepre = specialistURL+'/'+this.state.image_name;
		
		  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
			  loaderimg = specialistURL + '/'+this.state.image_name;
			  preview = <img className="img_class" src={loaderimg} alt="" />;
		  }else{
			  preview = <img className="img_class" src={imagepre} alt="" />;
		  }


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Category" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Category Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Category Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>
				<div className="form-group category-section">	
					<label>Product Category<span class="required" style={{ color: "red" }} > * </span> : </label>

				
								
								<Multiselect
						displayValue="label"
						onRemove={this.handleProductCategoryChange}

						onSelect={this.handleProductCategoryChange}
						value={this.state.selectedcategoryid}
						selectedValues={productcategorynamelist.filter(obj => selectedcategoryvalue.includes(obj.value))}
						options={this.state.productcategorynamelist}
						/>
	
					
					<div className="errorproductcategory"></div>
				</div>
				<div className="form-group">
					<label>Short Description<span class="required" style={{ color: "red" }} > * </span>:</label>
					{/* 	<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  /> */}
						<CKEditor
					data={this.state.short_description}
					//data={templatecontent}
					name="short_description" 
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errorshort_description"></div>
				</div>
				<div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
				</div>
				<div className="form-row">
									{/* <div className="form-left"> */}
										{this.state.tabgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Image Galleries</label>
												<div className='gallery-container'>
													{this.state.tabgalleries?.map((item) => (
													<div className='gallery-inner' key={item.id}>
														<img width="100px" height="100px" src={specialistURL+'/'+'tabimages'+'/'+item.tab_image} alt={`Image ${item.id}`}  />
														 <Link onClick={this.confirmPopup.bind(this, item.id,item.tab_image_path,item.tab_image,'tab')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									{/* </div> */}
								</div>
											
				<div className="form-group">					
					<label>Status :</label>
					<Select 
					value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
					options={lang.common.status_option} 
					onChange={this.handleChange}
					/>
				</div> 
			</div>
                  
			<div className="form-right">
			    <div className="form-group">					
				<label>Module <span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="module"
						   value={selectedModule?selectedModule:{ value: 'module1', label: 'Module1 (Doctor Listing)'}}
                            options={lang.common.module_option} 
							isSearchable = {false}
                            onChange={this.handleChangeModule}
                            />
	           </div> 

				<div className="form-group">					
					<label>Description:</label>
				{/* 		<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  /> */}
						<CKEditor
						data={this.state.description}
						//data={templatecontent}
						name="description" 
						onChange={this.onEditorChange1}
						type="classic"
						/>
					<div className="errordescription"></div>
				</div>
				

				<div className="form-group">					
					<label>Title Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						<textarea type="text" name="title_description" onChange={this.handleInputChange} className="form-control" value={this.state.title_description}  />
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					{(this.state.image_name !==null && this.state.image_name !=='')?<a href="javascript:void(0);" onClick={this.imageRemove.bind(this,this.state.categoryId)}>X</a>:''}
				
					<div className="errorloaderimage"></div>  
				</div> 
               <div className="form-group">					
					<label>Button Text</label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (categoryId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,categoryId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));