import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from 'ckeditor4-react';

	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			membership_name:'',
			description:'',
			amount:'',
			period:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedPlanOption: '',
			selectedvalue:'active',
			selectedPlanvalue:'',
			planLists:'',
			discount_percentage:'',
			visit_limit:'',
			discount_validity:'',
			discount_type:'',
			
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );

    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt.editor.getData()
        } );
		}, 1000);
     
    }

	componentDidMount() {
	
	console.log("component did mount")
	}
	  
// 	handleChange = selectedOption => {
//         this.setState({selectedOption})
//         this.setState({  selectedvalue : selectedOption.value});
//    };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'ins_cate'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
		}
		console.log(selOption.value, 'sdfg')
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => {
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedPlanvalue === '' && formdata.selectedPlanvalue!== 0){
		cate =formdata.selectedPlanOption.value;
	}else{
		cate = formdata.selectedPlanvalue;
	}
	const priceWithoutSymbol = formdata.amount.replace('$', '');

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		membership_name : formdata.membership_name,
		description:formdata.description,
		amount: priceWithoutSymbol,
		period: formdata.period,
		status: status,
		cate_id:cate,
		discount_type:formdata.discount_type,
		discount_percentage:formdata.discount_percentage,
		visit_limit:formdata.visit_limit,
		discount_validity:formdata.discount_validity,
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"membership/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/membership'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }
  
  validateForm() {
	const {membership_name,description,amount, period, selectedPlanOption,discount_percentage,visit_limit,discount_validity,discount_type} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!membership_name){
		formIsValid = false;
		$('.errormembership_name').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errormembership_name').html('');
	}

	if(!description){
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errordescription').html('');
	}
	
	if(!amount){
		formIsValid = false;
		$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.erroramount').html('');
	}

	if(!period){
		formIsValid = false;
		$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorperiod').html('');
	}

	if(!selectedPlanOption){
		formIsValid = false;
		$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorplan').html('');
	}

	
	
	if(!discount_type){
		formIsValid = false;
		$('.errordiscount_type').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errordiscount_type').html('');
	}

	if(!discount_percentage){
		formIsValid = false;
		$('.errordiscount_percentage').html('<span class="errorspan">Please fill the field</span>');
	}else if(discount_percentage){
		$('.errordiscount_percentage').html('');

		if(discount_percentage>100){
			formIsValid = false;

			$('.errordiscount_percentage').html('<span class="errorspan">percentage must be less than or equal to 100</span>');

		}

	}
	if(!visit_limit){
		formIsValid = false;
		$('.errorvisit_limit').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorvisit_limit').html('');
	}
	if(!discount_validity){
		formIsValid = false;
		$('.errordiscount_validity').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errordiscount_validity').html('');
	}
	
	this.setState({
		errors: errors
	});
	return formIsValid;
}

handlePriceChange = (e) => {
    const inputValue = e.target.value;

    // You can format the input value with a currency symbol here
    if(inputValue!=''){
    const formattedPrice = inputValue.replace(/[^\d.]/g, '');

    const priceWithSymbol = `$${formattedPrice}`;
   


    this.setState({
        amount: priceWithSymbol
      });
    }
  };

  render() {
	if(this.state.discount_type == 'flat')
	{
		$('.discount_amount').html('Prescription Discount ($):')
		
	}else{
		$('.discount_amount').html('Prescription Discount (%):')
	}	
	const {selectedOption,selectedPlanOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Membership" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Membership</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>Membership Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.membership_plan_option} 
                            onChange={this.handleChange.bind(this, 'ins_cate')}
                            />
							<div className="errorplan"></div>
	           </div>
			   <div className="form-group">
					<label>Amount Type<span class="required" style={{ color: "red" }} > * </span> : </label> <br></br>
				    <input type="radio" name="period" value="Month" onChange={this.handleInputChange} className="form-control" />
					<label >Monthly</label>
				    <input type="radio" name="period" value="Year" onChange={this.handleInputChange} className="form-control"   />
					<label >Yearly</label>
					<div className="errorperiod"></div>
				</div>

				<div className="form-group">
						<label>Discount type<span class="required" style={{ color: "red" }} > * </span> : </label>
						<br></br>
						<input onChange={this.handleInputChange} type="radio" name="discount_type" checked={this.state.discount_type=='flat'?'chekced':''} value="flat"/>
						<label>Flat Amount</label>
							<input onChange={this.handleInputChange} type="radio"  name="discount_type" checked={this.state.discount_type=='percentage'?'chekced':''} value="percentage" />
						<label>Percentage</label>
						<div className="errordiscount_type"></div>
					</div> 
				
				
				<div className="form-group">
					<label>Visit Limit <span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="visit_limit" onChange={this.handleInputChange} className="form-control" value={this.state.visit_limit}/>
					<div className="errorvisit_limit"></div>
				</div>
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errordescription"></div>
				</div>
			</div>
                  
			<div className="form-right">
			
			<div className="form-group">
					<label>Membership Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="membership_name" onChange={this.handleInputChange} className="form-control" value={this.state.membership_name}/>
					<div className="errormembership_name"></div>
				</div>
				<div className="form-group">
					<label>Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="amount" onChange={this.handlePriceChange} className="form-control" value={this.state.amount}/>
					<div className="erroramount"></div>
				</div>
			
					
						
				<div className="form-group">
					<label className='discount_amount'>Prescription  Discount (%)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="discount_percentage" onChange={this.handleInputChange} className="form-control" value={this.state.discount_percentage}/>
					<div className="errordiscount_percentage"></div>
				</div>
				
				<div className="form-group">
					<label>Discount Validity (Month)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="discount_validity" onChange={this.handleInputChange} className="form-control" value={this.state.discount_validity}/>
					<div className="errordiscount_validity"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));