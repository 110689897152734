import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl,apiUrl } from '../Config/Config';
import { GET_USERLIST, GET_DELETEUSER, GET_ACTIVATEUSER, GET_EXPORTUSER } from '../../actions';
import axios from 'axios';
class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          userlist:'',
          editable: false,
          priorityid:'',
          priorityvalue:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          confirmDelete:null,
      };
      this.deleteConfirm = this.deleteConfirm.bind(this);
      var qs = require('qs');
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
      this.props.getUserList(qs.stringify(postobject));

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   }


  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
  this.props.getUserList(qs.stringify(postobject))
  }

  handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
    [name]: value
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        //console.log(postObject);
        this.props.getUserList(qs.stringify(postObject));
      
  } 

   componentWillReceiveProps(Props){
    
    if (Object.keys(Props.userlist).length > 0) {
      if (Props.userlist[0]["status"] === "success") {

          this.setState({ totalRecord : Props.userlist[0].records_count, userlist: Props.userlist[0]["userlist"] }, function() {
            this.userlist();
          });
      }
    }   
	
	if(Props.changestatus !== this.props.changestatus){
      if (Props.changestatus[0]["status"] === "success") {
            var qs = require('qs');
            var postobject = {
           activePage: this.state.activePage,
            search_all: '',
            admin_id : localStorage.getItem("admin_id")
            };
            this.props.getUserList(qs.stringify(postobject));
      }
    }
	
	
	if(Props.exportuser !== this.props.exportuser){
		
      if (Props.exportuser[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportuser[0]["message"];
          window.open(url,
  '_blank' // <- This is what makes it open in a new window.
);
      }
    }
   }

   deleteConfirm=(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
      $('.confirm-action-delete').removeClass('show');
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        id  :id
      };
    axios.post(apiUrl+"user/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){ 
        this.setState({userlist: res.data.userlist});
      
        $('.success_message').html('<div class="status_sucess"><h3>Deleted Successfully</h3></div>');
                    window.location.reload(); 

      }
    }); 
    }else{
      $('.confirm-action-delete').removeClass('show');	
    }
  }
// deleteuser(id){
  
// }

activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id,
		  user_status: stat
        };
      this.props.getActivateUser(qs.stringify(postObject));
}
deletecategory(id){
  this.setState({confirmDelete : id})
  $('.confirm-action-delete-message').html('Are you sure delete the user?');	
  $('.confirm-action-delete').addClass('show');	
 }
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      this.props.getExportUser(qs.stringify(postObject));
}
  userlist() {
   console.log(this.props,'prop')
    var userlist = this.props.userlist[0];
    if (userlist != "undefined" && userlist != null) {
      if (userlist.status == "success") {
        if (Object.keys(userlist).length > 0) {
          const userlistDetails = userlist.userlist.map(
            (userlist, Index) => {
              let sno = Index+1;
              if(userlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }

              var mobileno = ((userlist.mobileno == null)||(userlist.mobileno == '')) ?  userlist.mobileno = 'N/A' : userlist.mobileno;
              return (
                <tr key={userlist.id}>
                <td>{sno}</td>
				 <td>
                  {userlist.username}
                  </td>
                  <td>
                  {userlist.firstname}
                  </td>
                  <td>{userlist.org_password}</td>
                  {/* <td>{userlist.domain_name}</td> */}
                  <td>{userlist.email}</td>
                  
				          <td>{mobileno}</td>
                  <td>{userlist.subscribed_plan_name} ({userlist.user_type})</td>
				        
                  <td>{status}</td>
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/view/'+userlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/edit/'+userlist.id}} className="" title="Edit">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'Active'?<Link onClick={this.activateuser.bind(this, userlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, userlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}    
                     <Link onClick={this.deletecategory.bind(this,userlist.id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link> 
                  </td>
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="user" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>

        <div className="listing-header"> 
          <div className="title">
            <h3>Users</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/user-add'>Add &nbsp;</a>
      		 {/* <a className="" href='/user-add'>Add &nbsp;|&nbsp;</a> */}
			 {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
           {/* <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Username</th>
                <th>Name</th>
                <th> Password</th>
                {/* <th>Domain</th> */}
                <th>Email</th>
				        <th>Contact</th>
               {/*<th>Domain</th>*/}
               <th>User Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.userlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
              <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.confirmDelete)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						  </div>
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    userlist: state.userlist,
    deleteuser: state.deleteuser,
	changestatus: state.changestatus,
	exportuser: state.exportuser

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (formVal) =>{
       dispatch({ type: GET_USERLIST, formVal});
    },
    getDeleteUser: (formPayload) =>{
      dispatch({ type: GET_DELETEUSER, formPayload });
    },
	getActivateUser: (formPayload) =>{
      dispatch({ type: GET_ACTIVATEUSER, formPayload });
    },
	getExportUser: (formPayload) => {
		 dispatch({ type: GET_EXPORTUSER, formPayload });
	}
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));