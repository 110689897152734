import React, { Component } from 'react';
import { lang } from'../Helpers/lang';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Select from 'react-select';
import $ from 'jquery';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import {GET_ADDFORM }  from '../../actions'; 
class Add extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
	      form_name:'',
	      form_one_column:true,
	      form_two_column:false,
	      form_field_name:'',
	      form_input_type:'',
	      form_input_type_value:'',
	      field_priorty:'',
	      form_field_name_arr:[],
	      form_input_type_arr:[],
	      form_input_type_value_arr:[],
	      field_priorty_arr:[],
	      field_required_checked:true,
	      field_required:'',
	      field_required_arr:[],
	      showlayout: false,
	      select_options:'',
	      select_options_arr:[],
	      selectedvalue: '',
          selectedOption:{ value: 'active', label: 'Active'},
          Loading:false,
		};
    this.deletesinglefield = this.deletesinglefield.bind(this);
 }
	
	componentDidMount() {
		  	if(localStorage.getItem("admin_id") === 'undefined' && localStorage.getItem('admin_id') === '' && localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
  }
	handleInputChange = (event) =>{
    const { name,value } = event.target;
    this.setState({[name]: value})
     if(name === 'form_column'){
       this.setState({form_one_column: !this.state.form_one_column});
       this.setState({form_two_column: !this.state.form_two_column});
    }
    if(name === 'field_required'){
        this.setState({field_required_checked: !this.state.field_required_checked});
    }
    if(name == 'select_options'){
        this.setState({select_options:value})
    }
  }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

 
 handleFormSubmit = () => {
				console.log('rtest')
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				
				var column_value = '';
				if(formPayload.form_one_column == true){
					column_value = 1;
				}else{
					column_value = 2;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					form_name: formPayload.form_name,
					form_column: column_value,
					form_field_name_arr: formPayload.form_field_name_arr,
					form_input_type_value_arr: formPayload.form_input_type_value_arr,
					field_priorty_arr: formPayload.field_priorty_arr,
					field_required_arr: formPayload.field_required_arr,
					select_options:formPayload.select_options_arr,
					type: formPayload.type,
					status      :status				
				};
				this.props.getAddForm(qs.stringify(postObject));

	}

  handlefieldTypeChange(event) {   
      this.setState({form_input_type:[event],form_input_type_value:event.value})
  }

  Addtolayout=()=>{
  	if(this.validateFormfield()){
			var valuerequired;
			if(this.state.field_required_checked == true){
			valuerequired = 'yes';
			}else{
			valuerequired = 'no';
			}
			this.setState({
			form_field_name_arr: this.state.form_field_name_arr.concat(this.state.form_field_name), form_input_type_value_arr: this.state.form_input_type_value_arr.concat(this.state.form_input_type_value),field_priorty_arr: this.state.field_priorty_arr.concat(this.state.field_priorty),select_options_arr: this.state.select_options_arr.concat(this.state.select_options),field_required_arr: this.state.field_required_arr.concat(valuerequired)
			});
			this.setState({showlayout: true},function(){
			this.setState({form_field_name:'',form_input_type:'',form_input_type_value:'',field_required_checked:true,field_priorty:'',select_options:''});
			this.getformlayout();
			})
  	}
  }

  validateFormfield() {
		const {form_field_name,form_input_type_value,field_required,field_priorty,select_options} = this.state;
		let errors = 0;
	
		if (!form_field_name) {
			errors++;
			$('.errorfieldname').html('<span class="errorspan">Please fill the field</span>');
		}else if(form_field_name){
			$('.errorfieldname').html('');
		}


		if (!form_input_type_value) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please select input type</span>');
		}else if(form_input_type_value){
			$('.errortype').html('');
		}

		if(!field_priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(field_priorty){
			$('.errorpriority').html('');
		}

		if(form_input_type_value == 'select' || form_input_type_value == 'checkbox' || form_input_type_value == 'radio'){
			if(!select_options){
				errors++;
				$('.erroroptions').html('<span class="errorspan">Please fill the field</span>');
			}else if(select_options){
				$('.erroroptions').html('');
			}
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

       componentWillReceiveProps(Props){

    	 	if(Props.formadd !== this.props.formadd){
    		if(Object.keys(Props.formadd).length > 0){
				this.setState({Loading:false});

					if(Props.formadd[0].status === "success"){

							$('.success_message').html('<div class="status_sucess"><h3>'+Props.formadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							//this.handleReset();
							this.props.history.push('/formbuilder');
							}
							.bind(this),
							3000
							);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+Props.formadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}

    		}
    	}

     }
  deletesinglefield(Index){
      let fieldname = [...this.state.form_field_name_arr];
      let fieldtype = [...this.state.form_input_type_value_arr];
      let fieldrequired = [...this.state.field_required_arr];
      let fieldpriorty = [...this.state.field_priorty_arr]; 
      fieldname.splice(Index, 1);
      fieldtype.splice(Index, 1);
      fieldrequired.splice(Index, 1);
      fieldpriorty.splice(Index, 1);
      this.setState({form_field_name_arr: fieldname,form_input_type_value_arr: fieldtype,field_required_arr:fieldrequired,field_priorty_arr:fieldpriorty});

  }
  getformlayout(){
      var form_field_value = this.state.form_field_name_arr;
      if (Object.keys(form_field_value).length > 0) {
      const formlayout = form_field_value.map(
      (datas, Index) => {
      let indexdata = Index+1;
      return (
            <div key={Index+'list'}>
            <li className="sortablecontainer-module-list">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {datas}
            <div className="sortablecontainer-module-edit-button">
            {/*<button>Edit</button>*/}
            <button onClick={this.deletesinglefield.bind(this,Index)}>Delete</button>
            </div>
            </li></div> );

      }
      );
      return formlayout;
      }
  }
  getdeleteall = () =>{
      this.setState({showlayout:false,form_field_name_arr:[],form_input_type_arr:[],form_input_type_value_arr:[],field_priorty_arr:[],field_required_arr:[],select_options_arr:[]});
  }

  render() {
  	console.log(this.state.select_options)
  	  	const {selectedOption } = this.state;
  return (
   <div className="wrapper"> 
    <Header />
    <MenuSidebar />		
    	<div className="content">	
    		<div className="content-wrapper">
    			<div className="success_message"></div>
					<div className="title">
						<h4>Add Form</h4>
					</div>
    			<div className="content-body">
  			
        			<div className="builderpage-module-pagewrapper">
                 <section className="builderpage-module-section">
                    {(this.state.showlayout == true)?<> <h2 className="typography-module-title">Form Field</h2>
                       <div className="sortablecontainer-module-wrapper">
                         <ul>
                           {this.getformlayout()}
                         </ul>
                         <div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div>
                      </div></>:''}
                  </section>
            <form className="BuilderPage-module-form builderpage-module-section" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>

                  <div className="form-group">
                  	<label>Form Name:</label>
                      <input type="text" className="form-control" placeholder="Form Name" name="form_name" value={this.state.form_name} onChange={this.handleInputChange} autoComplete="off"/>
                	</div>
                	<div className="form-group radio-check">
                	<label>Choose layout</label>
                        	<input className="form-check-input" type="radio" name="form_column" id="form_one_column" checked/>
                        	<label className="form-check-label" for="form_one_column">
                        	One Column
                        	</label>
                        	<input className="form-check-input" type="radio" name="form_column" id="form_two_column" />
                        	<label className="form-check-label" for="form_two_column">
                        	 Two Column
                        	</label>
                	</div>
                	<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
              <h2 className="typography-module-title">Field Creator</h2>
              	<div className="form-group">
              	<label>Name:</label>
                	<input type="text" name="form_field_name" className="form-control" value={this.state.form_field_name} placeholder="Field Name" onChange={this.handleInputChange} autoComplete="off" />
                <div className="errorfieldname"></div>
              	</div>
                <div className="form-group">
                  <label>Type: </label>
                  <Select 
                  value={this.state.form_input_type!=''?this.state.form_input_type:'Choose'}
                  options={lang.common.field_options} 
                  onChange={this.handlefieldTypeChange.bind(this)}
                  placeholder= 'Field Type'
                  isSearchable={false}
                  />
                    <div className="errortype"></div>
                </div>
                {(this.state.form_input_type_value == 'select' || this.state.form_input_type_value == 'checkbox' || this.state.form_input_type_value == 'radio')?<div className="form-group">
                <label>Options:</label>
                <input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
                <div className="erroroptions"></div>
                </div>:''}
                <div className="form-group">
                        <label>Field Required : </label>
                        <input onChange={this.handleInputChange} type="checkbox"  name="field_required" checked={this.state.field_required_checked}/><span>Yes</span>
               </div>
                <div className="form-group">
                        <label>Priority : </label>
                         <input type="text" name="field_priorty" className="form-control" value={this.state.field_priorty} placeholder="Field Priority" onChange={this.handleInputChange} autoComplete="off" />
                         <div className="errorpriority"></div>
               </div>
              <div className="submit-button">
                 <span className="btn btn_orange btn_minwid login_submit animate-btn2" onClick={this.Addtolayout}>Add</span>
              </div>
              <div className="">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
             </form>
          </div>
  			</div>	
  		</div>	
  	</div>
  </div>
  );
  }
  }

const mapStateTopProps = (state) => {
  return {
  	formadd     : state.formadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddForm: (formPayload) =>{
       dispatch({ type: GET_ADDFORM, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));