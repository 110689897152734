/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_DASHBOARDLIST, SET_DASHBOARDLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*get the list*/

export const watchGetDashboardList = function* () {
  yield takeEvery(GET_DASHBOARDLIST, workerGetDashboardList);
}

function* workerGetDashboardList({ formVal }) {
  try {
      
 const result = yield call(getDashboardList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DASHBOARDLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDashboardList(formPayload) {
   return Axios.post(apiUrl+'dashboard/list', formPayload);
} 


