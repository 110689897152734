import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Select from 'react-select';
import { lang } from '../Helpers/lang';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      faqlist:'',
      domainlist:[],
      selectedCateOption:'',
      selectedCatevalue:'',
      faqIDs:''
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"faq/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  faqlist: res.data.faqlist, totalRecord: res.data.records_count}, function() {
            
              this.faqlist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getfaqlist(qs.stringify(postobject))
    axios.post(apiUrl+"faq/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  faqlist: res.data.faqlist, totalRecord: res.data.records_count}, function() {
            this.faqlist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleChange = (selOption) => {       	
    this.setState({selectedCateOption:selOption})
    this.setState({  selectedCatevalue : selOption.value});
 };

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    category : formPayload.selectedCatevalue,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"faq/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  faqlist: res.data.faqlist, totalRecord: res.data.records_count}, function() {
          this.faqlist();
        });

    }
  }); 

} 



deleteConfirm=(confirmstatus,id)=>{
  if(confirmstatus == 'yes'){
    $('.confirm-action-delete').removeClass('show');
    
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };

  axios.post(apiUrl+"faq/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){       
      $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

      setTimeout(
      function() {
        $('.success_message').html('');
        window.location.reload(); 
      }
      .bind(this),
      3000
      );
      
    }
  }); 
  
}else{
  $('.confirm-action-delete').removeClass('show');	
}
}

activemembership(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };

        if(stat == "A"){
          axios.post(apiUrl+"faq/checkBeforeChange",qs.stringify(postObject)).then(res => {
            if(res.data.status === 'success'){
              this.change_status(postObject);
            }else{
              $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
              setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
            }
          });
        }else{
          this.change_status(postObject);
        }
      
}

change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"faq/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      window.location.reload(false);
    }
  }); 
}

checkRow = (id) => {
  var faqidArr = [...this.state.faqIDs];
  const index = faqidArr.findIndex(faq_list => faq_list === id);
  if(index > -1) {
    faqidArr = [...faqidArr.slice(0, index), ...faqidArr.slice(index + 1)]
  } else {
    faqidArr.push(id);   
  }
  this.setState({faqIDs: faqidArr});
}


faqlist() {
  let catImg = '';
  let preview = '';
    var faqlist = this.state.faqlist;
    if (faqlist != "undefined" && faqlist != null) {
    
      if (faqlist.length > 0) {

        if (Object.keys(faqlist).length > 0) {
          const helperlistDetails = faqlist.map(
            (faqlist, Index) => {
              let sno = Index+1;
              

               if(faqlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }

              var answer = faqlist.faq_answer;
             
              return (
                <tr key={faqlist.id}>
                <td><input type='checkbox' onClick={this.checkRow.bind(this,faqlist.id)} /></td>
      					{/* <td width="10px">{sno}</td> */}
      					<td>{faqlist.faq_question}</td>
      					<td>{answer.substring(0,50)}</td>
      					<td>{faqlist.cate_name}</td>
                <td width="30px">{status}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/faq-view/'+faqlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/faq-edit/'+faqlist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.activemembership.bind(this, faqlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activemembership.bind(this, faqlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      

                   
                    <Link onClick={this.deletedata.bind(this,faqlist.id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  

  export = () => {
    // console.log('aifeedidArr', this.state.faqIDs);
    var faqIDs = this.state.faqIDs;
    if(faqIDs.length > 0){
      var qs = require('qs');
  
      var postObject = {   
          ids: faqIDs   
      };
      // console.log(postObject, 'postObject'); return;
      axios.post(apiUrl+"faq/export",qs.stringify(postObject)).then(res => {
        // console.log(res, 'ai_res');
        if(res.data.status !== ''){
            $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
                  
            if(res.data.status == 'success'){
              var url = res.data.url;
              var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
              window.open(cleanurl, '_blank', 'noreferrer');
              window.location.reload();
            }
  
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      }); 
    }else{
      alert('Please select atleast one');
    }
  }
  deletedata(id){
    this.setState({rowid : id})
    $('.confirm-action-delete-message').html('Are you sure delete the Faq?');	
    $('.confirm-action-delete').addClass('show');	
  }
  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Faq" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Faq List</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div class="input_search">
                    <div className="search-inner search_input">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     
                    <div className="erroremail"></div>
                    </div>
                    <div className="search-inner search_select">
                    <Select name="category" options={lang.common.categories_option1} 
                          // onChange={this.handleInputChange}
                            onChange={this.handleChange}
                            />
                    </div>
                    <div class="search-inner search_btn">
                    <button className="" type="submit">Submit</button>

                    </div>
                    </div>
                 
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/faq-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/faq-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a> &nbsp;|&nbsp;
                    <a className="" onClick={this.export}>Export <i className="export-icon icon" aria-hidden="true"></i></a>
                    <a class="exportcsv_url" href=""></a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th></th>
                  {/* <th width="10px">S.No</th> */}
			            <th>Faq Question</th>
			            <th>Faq Answer</th>
			            <th>Category</th>
                  <th width="30px">Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.faqlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action-delete">
        <p className="confirm-action-delete-message"></p>
        <a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.rowid)} className="btn">Yes</a>
        <a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
        <span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
      </div>
    </div>
    );
  }
}
export default (withRouter(List));