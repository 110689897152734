import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import { GET_ADDCOUPON }  from '../../actions'; 

class Add extends Component {
	
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			coupon_code:'',
			coupon_discount:'',
			coupon_description:'',			
			status:'',		   
			Loading:false,
			selectedvalue: '',
			selectedDisvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
            selectedDisOption:{ value: 'percentage', label: 'Percentage'},
			startdate:'',
			enddate:''
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleChangeDate   = this.handleChangeDate.bind(this);
		this.handleChangeEndDate   = this.handleChangeEndDate.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	 handleDisChange = selectedDisOption => {
        this.setState({selectedDisOption})
        this.setState({  selectedDisvalue : selectedDisOption.value});
    };
	
	
	componentDidMount() {
		document.title = PageTitle('Coupon Add');
    }


	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var distype = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.selectedDisvalue === '' && formPayload.selectedDisvalue!== 0){
				distype =formPayload.selectedDisOption.value;
				}else{
				distype = formPayload.selectedDisvalue;
				}
				
				if(formPayload.startdate!='' && formPayload.startdate!=null){
					var s_date = moment(formPayload.startdate).format('YYYY-MM-DD');
				}
				if(formPayload.enddate!='' && formPayload.enddate!=null){
					var e_date = moment(formPayload.enddate).format('YYYY-MM-DD');
				}
			  
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					coupon_code  : formPayload.coupon_code,
					distype      : distype,
					coupon_discount    : formPayload.coupon_discount,
					coupon_description    : formPayload.coupon_description,
					status       : status,
					 from_date : s_date,
					to_date : e_date,
				};		
//console.log(postObject);				
				
				this.props.getAddCoupon(qs.stringify(postObject));
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {

		const {coupon_code,coupon_discount,coupon_description,startdate,enddate} = this.state;

		let errors = 0;
	
		if (!coupon_code) {
			errors++;
			$('.errorcoupon_code').html('<span class="errorspan">Please fill the error field</span>');
		}else if(coupon_code.length < 6){
			errors++;
			$('.errorcoupon_code').html('<span class="errorspan">6 Alpha numeric charcters required</span>');
		}else if(/[^A-Za-z0-9 ]/.test( coupon_code ) ) {
			errors++;
			$('.errorcoupon_code').html('<span class="errorspan">Input is not valid</span>');
		}else{
			$('.errorcoupon_code').html('');
		}
		
		if (!coupon_discount) {
			errors++;
			$('.errorcoupon_discount').html('<span class="errorspan">Please fill the field</span>');
		}else if(/[^0-9\./]/.test( coupon_discount ) ) {
			errors++;
			$('.errorcoupon_discount').html('<span class="errorspan">Input is not valid</span>');
		}else{
			$('.errorcoupon_discount').html('');
		}
		
		if (!startdate) {
			errors++;
			$('.errorstartdate').html('<span class="errorspan">Please fill the field</span>');
		}else {
			$('.errorstartdate').html('');
		}
		
		if (!enddate) {
			errors++;
			$('.errorenddate').html('<span class="errorspan">Please fill the field</span>');
		}else {
			$('.errorenddate').html('');
		}

		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.couponadd !== this.props.couponadd){
    		if(Object.keys(Props.couponadd).length > 0){
				this.setState({Loading:false});
					if(Props.couponadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.couponadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.couponadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }
 handleChangeDate(date) {	
      this.setState({
        startdate: date
      });
    }
   handleChangeEndDate(date){
	   this.setState({
        enddate: date
      });
   }
  render() {
  	const {selectedOption,selectedDisOption} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="coupons" />  

	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Coupon</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	
			
               <div className="form-group">
					<label>Coupon Code:</label>
				<input type="text" name="coupon_code" onChange={this.handleInputChange} className="form-control" value={this.state.coupon_code} autoComplete="off" />
					<div className="errorcoupon_code"></div>
                </div>
				
				
				
				<div className="form-group">					
					<label>Discount Type:</label>
						   <Select 
						   value={selectedDisOption?selectedDisOption:{ value: 'percentage', label: 'Percentage'}}
                            options={lang.common.discount_option} 
                            onChange={this.handleDisChange}
                            />
				</div>
				
				<div className="form-group">
					<label>Discount Amount:</label>
				    <input type="text" name="coupon_discount" onChange={this.handleInputChange} className="form-control" value={this.state.coupon_discount} autoComplete="off" />
					<div className="errorcoupon_discount"></div>
				</div>
				<div className="form-group">
					<label>Description:</label>
					<textarea className="form-control" name="coupon_description" onChange={this.handleInputChange} value={this.state.coupon_description} id="" rows="5"  autoComplete="off" />
					<div className="errorcoupon_description"></div>
				</div>
				
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				<div className="form-group start-date">					
					<label>Start Date:</label>
					<DatePicker
                     placeholderText="select start date"
                      dateFormat="dd/MM/yyyy"
                       selected={this.state.startdate}
                        className="form-control"
                        onChange={this.handleChangeDate}  />
						<div className="errorstartdate"></div>
				</div>
				<div className="form-group start-date">					
					<label>End Date:</label>
					<DatePicker
					 placeholderText="select end date"
					  dateFormat="dd/MM/yyyy"
					   selected={this.state.enddate}
						className="form-control"
						onChange={this.handleChangeEndDate}  />
						<div className="errorenddate"></div>
				</div>				
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	couponadd     : state.couponadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddCoupon: (formPayload) =>{
       dispatch({ type: GET_ADDCOUPON, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));