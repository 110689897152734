import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useState } from "react";
import { Button } from 'react-bootstrap';
import CKEditor from 'ckeditor4-react';
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			product_name:'',
			price:'',
			image:'',
			expenses:'',
			subscriptionprice:'',
			loader_image_preview:'',
			loader_image_name: '',
			loader_image: 'file',
			description:'',
		    cat_name:'',
			doctor_visited:false,
			no_of_visite:'',
			categorynamelist:[],
			selectedOption:{ value: 'active', label: 'Active'},
			selectedProductOption:{ value: '2', label: 'Shopping Product'},
			selectedvalue:'active',
			selectedproductvalue:'2',
			ebagproduct:false,
			quantity:''
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleProductChange = this.handleProductChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );

		this.onChangeHandler = this.onChangeHandler.bind(this);
    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt.editor.getData()
        } );
		}, 1000);
     
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };
   handleProductChange = selectedProductOption => {
	this.setState({selectedProductOption})
	this.setState({  selectedproductvalue : selectedProductOption.value});
	var productType = selectedProductOption.value;

	if(productType==1){
		$('.pricediv').addClass('hide');
	}else{
		$('.pricediv').removeClass('hide');
	}
	
};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
   
		if(name === 'doctor_visited'){
			if(this.state.doctor_visited==false){
				$('.doctor_visite_count').show();
			}
			else{
				$('.doctor_visite_count').hide();
			}
			this.setState({doctor_visited: !this.state.doctor_visited});			
	    }	
	   	if(name ==='no_of_visite'){
			if(value<1){
				this.setState({no_of_visite:''});
			}
	   	}
		   if(name == 'ebagproduct'){
			this.setState({ebagproduct: !this.state.ebagproduct})
		}
  }
  handleClickSelecetCategory = (event) => {
	const selectedIndex = event.target.options.selectedIndex;
	var keys = event.target.options[selectedIndex].getAttribute('data-key');
	this.setState({
			cat_name: keys,		
	});
}

componentDidMount() {
	
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				});
			
			}else{
				
			}
		});
	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		};
		reader.readAsDataURL(file);
	}


  handleSubmit = () => {
	if(this.validateForm()){
		
	const formdata = this.state;
	var qs = require('qs');
	var status = '';
	var product_type = '';
	var doctor_visited = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	
	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}
	if(formdata.selectedproductvalue === ''){
		product_type =formdata.selectedProductOption.value;
		}else{
			product_type = formdata.selectedproductvalue;
		}
	if(formdata.doctor_visited === true){
		doctor_visited ='Yes';
		}else{
			doctor_visited = 'No';
		}
		const priceWithoutSymbol = formdata.price.replace('$', '');
		const subpriceWithoutSymbol = formdata.subscriptionprice.replace('$', '');

	var img_name = this.state.image;

	if(formdata.ebagproduct ==  true)
	{
		var ebagproduct = 'yes';
	}else{
		var ebagproduct = 'no';
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		product_name : formdata.product_name,
		price: priceWithoutSymbol,
		category_id:formdata.cat_name,
		description: formdata.description,
		image : formdata.image,
		expenses: formdata.expenses,
		subscriptionprice:subpriceWithoutSymbol,
		status: status,
		product_type: product_type,
		ebagproduct: ebagproduct,
		quantity: formdata.quantity
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"product/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/product'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
	}
  }
	validateForm() {
		const {product_name,cat_name,
		price,expenses,
		// quantity,
		prescription_detail,
		doctor_visited,no_of_visite,selectedproductvalue,subscriptionprice,quantity} = this.state;

		let errors = {};
      	let formIsValid = true;

		if(this.state.ebagproduct ==  false){
			if(!cat_name){
				formIsValid = false;
				$('.errorcategory_name').html('<span class="errorspan">Please fill the field</span>');
			}else{
				
				$('.errorcategory_name').html('');
			}
		}


		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}

		if(!quantity){
			formIsValid = false;
			$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
		}else if(quantity){
			$('.errorquantity').html('');	
		}
		if(!selectedproductvalue){
        	formIsValid = false;
        	$('.errorproductype').html('<span class="errorspan">Please select product type</span>');
		}else{
			
			$('.errorproductype').html('');
		}
		if(this.state.ebagproduct == false){

			if((selectedproductvalue==2 || selectedproductvalue==3 )){
				if (!price) {
					formIsValid = false;
					$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
				}else if(price){
					$('.errorprice').html('');
				}
				if (!subscriptionprice) {
					formIsValid = false;
					$('.errorsubscriptionprice').html('<span class="errorspan">Please fill the field</span>');
				}else if(subscriptionprice){
					$('.errorsubscriptionprice').html('');
				}
			}
		}
		
		// if(doctor_visited==true)
		// {
		// 	if (!no_of_visite) {
		// 		formIsValid = false;
		// 		$('.errorno_of_visite').html('<span class="errorspan">Please fill the field</span>');
		// 	}else if(no_of_visite){
		// 		$('.errorno_of_visite').html('');
		// 	}
		// }
		
		// if (!expenses) {
		// 	formIsValid = false;
		// 	$('.errorexpenses_name').html('<span class="errorspan">Please fill the field</span>');
		// }else if(expenses){
		// 	$('.errorexpenses_name').html('');
		// }
     
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			price: priceWithSymbol
		  });
		}
	  };
	  handlesubPriceChange = (e) => {
		const inputValue = e.target.value;	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');
	    const priceWithSymbol = `$${formattedPrice}`;
		this.setState({
			subscriptionprice: priceWithSymbol
		  });
		}
	  };
  render() {

	let preview = '';
	const {selectedOption,categorynamelist,selectedProductOption,selectedproductvalue} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Product" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Product</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.medicine_name}/>
					<div className="errorproduct_name"></div>
				</div>
				<div className="form-group">					
					<label>Ebag product:</label>
						 <input onChange={this.handleInputChange} type="checkbox" name="ebagproduct" checked={this.state.ebagproduct}/>
	           	</div> 
				<div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div>
				{this.state.ebagproduct == false &&
				<div className="form-group">
					<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={current.handleClickSelecetCategory}  >                    
                    {this.state.categorynamelist.map((categorynamelist) => <option key={categorynamelist.value} data-key={categorynamelist.value} value={categorynamelist.value} >{categorynamelist.label}</option>)}
                    </select>
					<div className="errorcategory_name"></div>
				 </div>}

				<div className="form-group">					
					<label>Product Description:</label>
						<CKEditor
						data={this.state.description}
						//data={templatecontent}
						onChange={this.onEditorChange}
						type="classic"
						/>
					<div className="errordescription"></div>
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
				{/* <div className="form-group">
						<input onChange={this.handleInputChange} type="checkbox"  name="doctor_visited"  checked={this.state.doctor_visited}/>
						<span>Doctor Visit</span>
				</div> 	 */}
			</div>

			<div className="form-right">

			<div className="form-group">					
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="product_type"
							value={selectedProductOption?selectedProductOption:{ value: '2', label: 'Shopping Product'}}
							options={lang.common.productType_option} 
							isSearchable = {false}
							onChange={this.handleProductChange}
						/>
	           	</div> 
			
			{/* <div className="form-group">
					<label>Expenses<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="expenses" onChange={this.handleInputChange} className="form-control" value={this.state.expenses}/>
					<div className="errorexpenses_name"></div>
				</div> */}
				{selectedproductvalue == 2 || selectedproductvalue == 3 ? <div className="form-group pricediv hide">
					<label>Price ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="price" onChange={this.handlePriceChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>: '' }
				{this.state.ebagproduct == false && selectedproductvalue == 2 || selectedproductvalue == 3 ?<div className="form-group pricediv">
					<label>Subscription Price ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="subscriptionprice" onChange={this.handlesubPriceChange} className="form-control" value={this.state.subscriptionprice}/>
					<div className="errorsubscriptionprice"></div>
				</div> : '' }
				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="status"
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange}
						/>
	           	</div> 
			
				{/* <div className="form-group doctor_visite_count">
					<label>No of Visit<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="no_of_visite" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_visite}/>
					<div className="errorno_of_visite"></div>
				</div> */}
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));