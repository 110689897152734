/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDPRODUCT, GET_ACTIVATEPRODUCT, SET_ACTIVATEPRODUCT, SET_ADDPRODUCT, GET_EDITPRODUCT, SET_EDITPRODUCT, GET_PRODUCTLIST, SET_PRODUCTLIST, GET_DELETEPRODUCT, SET_DELETEPRODUCT, GET_IMPORTPRODUCT, SET_IMPORTPRODUCT,GET_PRODUCTDETAILLIST, SET_PRODUCTDETAILLIST,GET_VIEWPRODUCTLIST, SET_VIEWPRODUCTLIST } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*export const watchGetUserList = function* () {
  yield takeEvery(GET_USERLIST, workerGetUserList);
}

function* workerGetUserList() {
	  
      var url = apiUrl+'user/list';
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_USERLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}*/


/*save the user*/

export const watchGetProductList = function* () {
  yield takeEvery(GET_PRODUCTLIST, workerGetProductList);
}

function* workerGetProductList({ formVal }) {
  try {
      
 const result = yield call(getProductList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PRODUCTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getProductList(formPayload) {
   return Axios.post(apiUrl+'product/list', formPayload);
} 




/*get user details*/ 

export const watchGetProductDetailList = function* () {
  yield takeEvery(GET_PRODUCTDETAILLIST, workerGetProductDetailList);
}

function* workerGetProductDetailList({ productId }) {

    var url = apiUrl+'product/listdetail?id='+productId;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_PRODUCTDETAILLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 


/*save the user*/

export const watchGetProductAdd = function* () {
  yield takeEvery(GET_ADDPRODUCT, workerGetProductAdd);
}

function* workerGetProductAdd({ formPayload }) {
  try {
      console.log(formPayload)
 const result = yield call(getAddProduct, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDPRODUCT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddProduct(formPayload) {
   return Axios.post(apiUrl+'product/add', formPayload);
} 


/*edit the user*/

export const watchGetEditProduct = function* () {
  yield takeEvery(GET_EDITPRODUCT, workerGetEditProduct);
}

function* workerGetEditProduct({ formPayload }) {
  try {
      
 const result = yield call(getEditProduct, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITPRODUCT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditProduct(formPayload) {
   return Axios.post(apiUrl+'product/edit', formPayload);
} 


/*delete the user*/

export const watchGetDeleteProduct = function* () {
  yield takeEvery(GET_DELETEPRODUCT, workerGetDeleteProduct);
}

function* workerGetDeleteProduct({ formPayload }) {
  try {
      
 const result = yield call(getDeleteProduct, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEPRODUCT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteProduct(formPayload) {
   return Axios.post(apiUrl+'product/delete', formPayload);
} 




/*change status the user*/

export const watchActivateProduct = function* () {
  yield takeEvery(GET_ACTIVATEPRODUCT, workerActivateProduct);
}

function* workerActivateProduct({ formPayload }) {
  try {
      
 const result = yield call(getActivateProduct, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEPRODUCT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateProduct(formPayload) {
   return Axios.post(apiUrl+'product/change_status', formPayload);
} 

/*list of product*/
export const watchGetViewProductList = function* () {
  yield takeEvery(GET_VIEWPRODUCTLIST, workerGetViewProductList);
}

function* workerGetViewProductList({ productid }) {
  try {
    const result = yield call(getViewProductList, productid);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VIEWPRODUCTLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getViewProductList(productid){
   return Axios.post(apiUrl+'product/view', productid);
}

