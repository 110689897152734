import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from 'axios';
import $ from 'jquery';
import {
  Table
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl, apiUrl,locationURL} from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          locationlist:'',
          editable: false,
          activePage: 1,
          totalRecord: '',
          search_all:'',
          confirmDelete:null,
      };

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {

    var qs = require('qs');
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: localStorage.getItem('admin_id')  
    };

    axios.post(apiUrl+"location/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){
 
          this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
            this.locationlist();
          });

      }
    }); 
   }


  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    axios.post(apiUrl+"location/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){
 
          this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
            this.locationlist();
          });

      }
    }); 
  }

  handleInputChange(event) {
  const {name, value} = event.target;      
  this.setState({
    [name]: value
  });
 
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postobject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        axios.post(apiUrl+"location/list",qs.stringify(postobject)).then(res => {
          if(res.data.status === 'success'){
     
              this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
                this.locationlist();
              });
    
          }else{
            this.setState({  locationlist: '', totalRecord: 0}, function() {
              this.locationlist();
            });
          }
        });       
  } 
  componentWillReceiveProps(Props){ 
    if(Props.changestatus !== this.props.changestatus){
        if (Props.changestatus[0]["status"] === "success") {
              var qs = require('qs');
              var postobject = {
            activePage: this.state.activePage,
              search_all: '',
              admin_id : localStorage.getItem("admin_id")
              };
              this.props.getUserList(qs.stringify(postobject));
        }
    }	
    if(Props.exportuser !== this.props.exportuser){		
        if (Props.exportuser[0]["status"] === "success") {
          let url =baseUrl+"/"+Props.exportuser[0]["message"];
            window.open(url,
            '_blank' // <- This is what makes it open in a new window.
          );
        }
    }
   }

   deleteConfirm=(confirmstatus,id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id
        };
        axios.post(apiUrl+"location/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){
     
            $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

              setTimeout(
              function() {
                $('.success_message').html('');
                this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
                  this.locationlist();
                });
              }
              .bind(this),
              3000
              );
          }
        }); 
}else{
  $('.confirm-action-delete').removeClass('show');	
}
}

activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
		      status: stat
        };
      axios.post(apiUrl+"location/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
          var qs = require('qs');
          var postobject = {
            activePage: 1,
            search_all: '',
            admin_id: localStorage.getItem('admin_id')  
          };
          axios.post(apiUrl+"location/list",qs.stringify(postobject)).then(res => {
            if(res.data.status === 'success'){
                this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
                  this.locationlist();
                });
      
            }
          }); 
  
        }
      }); 
}
verifiedlocation(id, Verification){
	let stat = ''
		if(Verification == 'checked'){
			stat = "No";
		}else{
			stat = "Yes";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
		      status: stat
        };
      axios.post(apiUrl+"location/cerificate_verify",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
          var qs = require('qs');
          var postobject = {
            activePage: 1,
            search_all: '',
            admin_id: localStorage.getItem('admin_id')  
          };
          axios.post(apiUrl+"location/list",qs.stringify(postobject)).then(res => {
            if(res.data.status === 'success'){
                this.setState({  locationlist: res.data.locationlist, totalRecord: res.data.records_count}, function() {
                  this.locationlist();
                });
      
            }
          }); 
  
        }
      }); 
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      this.props.getExportUser(qs.stringify(postObject));
}
locationlist() {
  var locationlist = this.state.locationlist;
  if (locationlist != "undefined" && locationlist != null) {
    if (Object.keys(locationlist).length > 0) {
      const locationlistDetails = locationlist.map(
        (data, Index) => {
          let sno = Index+1;

          if(data.status == 'A'){
            var status = 'Active';
          }else{
              status = 'Inactive';
          }

          

        

          return (
            <tr key={data.id}>
            <td>{sno}</td>
              <td>
              {data.location}
              </td>
            
              <td>{status}</td> 
           
              <td className="actiongroup">
                 <Link to={{ pathname: '/location-view/'+data.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                 <Link to={{ pathname: '/location-edit/'+data.id}} className="" title="Duplicate">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                 {(status == 'Active'?<Link onClick={this.activateuser.bind(this, data.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, data.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}   
                 
                 <Link onClick={this.deletedata.bind(this,data.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link>    
              </td>
            </tr>
          );
        }
      );
      return locationlistDetails;
    } else {
    return (
      <tr>
        <td colSpan="10" className="v-align-nr">
          No Result
        </td>
      </tr>
    );
  }
   }   
}

deletedata(id){
  this.setState({rowid : id})
  $('.confirm-action-delete-message').html('Are you sure delete the location?');	
  $('.confirm-action-delete').addClass('show');	
 }

  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="location" />  

  
  <div className="content"> 
    <div className="content-wrapper">
      <div class="content-wrapper-before"></div>
      <div className="car-listing-wrap leaa-admin-head card">
      <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>Locations</h3>
          </div>
             <div className="search-list">
                <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                </form>
          </div> 
          <div className="add_button">  
      		 <a className="" href='/location-add'>Add</a>
			     {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="ft-upload" aria-hidden="true"></i></Link>*/}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Location</th>
            
                <th>Status</th>
               
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.locationlist()}</tbody>
          </Table>
          <div className="text-center">
             <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            /> 
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
  <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.rowid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						  </div>


    </div>
    );
  }
}
export default withRouter(List);