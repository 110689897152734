import { SET_ADDCATEGORY } from '../actions';

const categoryadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCATEGORY:
      return [...action.value];  
    default: return state;
  }
}

export default categoryadd;
