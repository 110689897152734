import { all } from "redux-saga/effects";
import { watchGetLoginData } from "./login";
import { watchGetSettingData, watchGetUpdateSetting, watchGetUpdatePluginContent, watchGetPluginContent }from "./setting";
import { watchGetChangePassword }from "./changepassword";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetProfileDetails, watchGetUpdateProfile } from './profile';
import {  watchGetCompanyList,watchGetAddCompany, watchGetEditCompany, watchGetCompanyDetailList  } from './company';
import {watchGetUserList, watchActivateUser, watchExportUser,watchGetEditUser, watchGetUserDetailList,watchGetAddUser,watchGetImportUser,watchGetViewUserList} from './user';
import { watchGetFontList, watchGetFontName, watchGetAddFont, watchGetDeleteFont, watchGetEditFont, watchGetFontDetailList } from './font';

import { watchGetTemplateList, watchGetTemplateDetail, watchGetTemplateAdd, watchGetTemplateDelete, watchGetTemplateEdit } from './template';
import { watchGetPaymentList,watchGetViewPaymentList } from './paymenthistory';
import { watchGetDashboardList } from './dashboard';
import { watchGetCategoryList, watchActivateCategory,watchGetCategoryAdd,watchGetCategoryDetailList,watchGetEditCategory,watchGetDeleteCategory,watchGetViewCategoryList } from './category';

import { watchGetAddCms, watchGetCmsList, watchGetEditCms, watchGetDeleteCms, watchGetCmsDetail,watchActivateCms } from './cms';
import { watchGetProductList, watchActivateProduct,watchGetProductAdd,watchGetProductDetailList,watchGetEditProduct,watchGetDeleteProduct,watchGetViewProductList } from './product';

import { watchGetAddPackage, watchGetPackageList, watchGetEditPackage, watchGetDeletePackage, watchGetPackageDetail,watchActivatePackage } from './package';

import { watchGetAddForm, watchGetFormList, watchGetEditForm, watchGetDeleteForm, watchGetFormDetail,watchActivateForm, watchGetFormEntriesList,watchGetFormEntryDetail} from './formbuilder';

import { watchGetCouponList, watchGetCouponDetailList, watchGetAddCoupon,watchGetEditCoupon } from './coupon';


export default function*() {
  yield all([
       watchGetLoginData(),
       watchGetSettingData(),
       watchGetUpdateSetting(),
       watchGetChangePassword(),
       watchGetForgetPassword(),
       watchGetProfileDetails(),
       watchGetUpdateProfile(),
       watchGetCompanyList(),
	     watchGetAddCompany(),
       watchGetEditCompany(),
       watchGetEditFont(),
       watchGetFontList(),
       watchGetFontName(),
       watchGetAddFont(),
  	   watchGetUserList(),
  	   watchActivateUser(),
  	   watchExportUser(),
       watchGetDeleteFont(),
       watchGetEditUser(),
       watchGetUserDetailList(),
       watchGetAddUser(),
	     watchGetImportUser(),
       watchGetTemplateList(),
       watchGetTemplateDetail(),
       watchGetTemplateAdd(),
       watchGetTemplateDelete(),
       watchGetTemplateEdit(),
       watchGetViewUserList(),
       watchGetPaymentList(),
       watchGetViewPaymentList(),
       watchGetDashboardList(),
       watchGetCompanyDetailList(),
       watchGetFontDetailList(),
       watchGetAddCms(),
       watchGetCmsList(),
       watchGetEditCms(),
       watchGetCmsDetail(),
       watchActivateCms(),
       watchGetCategoryList(),
       watchGetCategoryAdd(),
       watchGetCategoryDetailList(),
       watchGetEditCategory(),
       watchGetDeleteCategory(),
       watchGetViewCategoryList(),
       watchActivateCategory(),
       watchGetProductList(),
       watchGetProductAdd(),
       watchGetProductDetailList(),
       watchGetEditProduct(),
       watchGetDeleteProduct(),
       watchGetViewProductList(),
       watchActivateProduct(),

       watchGetAddPackage(),
       watchGetPackageList(),
       watchGetEditPackage(),
       watchGetPackageDetail(),
       watchActivatePackage(),

       watchGetAddForm(),
       watchGetFormList(),
       watchGetEditForm(),
       watchGetFormDetail(),
       watchActivateForm(),

       watchGetFormEntriesList(),
       watchGetFormEntryDetail(),

       watchGetCouponDetailList(),
       watchGetAddCoupon(),
       watchGetEditCoupon(),
       watchGetCouponList(),
       watchGetPluginContent(),
       watchGetUpdatePluginContent(),

  ]);
}
