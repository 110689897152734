import { SET_EDITPRODUCT } from '../actions';

const productedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITPRODUCT:
      return [...action.value];  
    default: return state;
  }
}

export default productedit;
