/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import {  GET_ADDFORM, SET_ADDFORM, GET_FORMLIST, SET_FORMLIST, GET_EDITFORM, SET_EDITFORM, GET_FORMDETAIL, SET_FORMDETAIL,GET_ACTIVATEFORM, SET_ACTIVATEFORM,GET_FORMENTRIESLIST, SET_FORMENTRIESLIST, GET_FORMENTRYDETAIL, SET_FORMENTRYDETAIL} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


export const watchGetAddForm = function* () {
  yield takeEvery(GET_ADDFORM, workerGetAddForm);
}

function* workerGetAddForm({ formPayload }) {
  try {
    const result = yield call(getAddForm, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ADDFORM, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddForm(formPayload) {
   return Axios.post(apiUrl+'formbuilder/add', formPayload);
} 


/*list*/



export const watchGetFormList = function* () {
  yield takeEvery(GET_FORMLIST, workerGetFormList);
}

function* workerGetFormList({ formVal }) {
  try {
      
 const result = yield call(getFormList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_FORMLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getFormList(formVal) {
   return Axios.post(apiUrl+'formbuilder/list', formVal);
} 


/*get Cms detail*/

export const watchGetFormDetail = function* () {
  yield takeEvery(GET_FORMDETAIL, workerGetFormDetail);
}

function* workerGetFormDetail({ formId }) {
  var url = apiUrl+'formbuilder/formdetail?id='+formId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FORMDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  


/*edit the vehicle*/

export const watchGetEditForm = function* () {
  yield takeEvery(GET_EDITFORM, workerGetEditForm);
}

function* workerGetEditForm({ formPayload }) {
  try {
      
 const result = yield call(getEditForm, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITFORM, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditForm(formPayload) {
   return Axios.post(apiUrl+'formbuilder/edit', formPayload);
} 



/*delete the cms*/

export const watchGetDeleteForm = function* () {
  yield takeEvery(GET_DELETEFORM, workerGetDeleteForm);
}

function* workerGetDeleteForm({ formPayload }) {
  try {
      
 const result = yield call(getDeleteForm, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEFORM, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteForm(formPayload) {
   return Axios.post(apiUrl+'formbuilder/delete', formPayload);
} 


/*change status the user*/

export const watchActivateForm = function* () {
  yield takeEvery(GET_ACTIVATEFORM, workerActivateForm);
}

function* workerActivateForm({ formPayload }) {
  try {
      
 const result = yield call(getActivateForm, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEFORM, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateForm(formPayload) {
   return Axios.post(apiUrl+'formbuilder/change_status', formPayload);
} 


/*entrieslist*/



export const watchGetFormEntriesList= function* () {
  yield takeEvery(GET_FORMENTRIESLIST, workerGetFormEntriesList);
}

function* workerGetFormEntriesList({ formVal }) {
  try {
      
 const result = yield call(getFormEntriesList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_FORMENTRIESLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getFormEntriesList(formVal) {
   return Axios.post(apiUrl+'formbuilder/userformentrieslist', formVal);
} 



  /*view payment*/
export const watchGetFormEntryDetail = function* () {
    yield takeEvery(GET_FORMENTRYDETAIL, workerGetFormEntryDetail);
}
  
function* workerGetFormEntryDetail({ entriesID }) {
  try {
    const result = yield call(getFormEntryDetail, entriesID);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FORMENTRYDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getFormEntryDetail(entriesID){
   return Axios.post(apiUrl+'formbuilder/userformentryview', entriesID);
}