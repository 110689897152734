import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const locationid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      locationid: locationid,
      locationlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :locationid
    };

    axios.post(apiUrl+"location/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ locationlistdata: res.data.locationlistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Location View');
   }
  render() {
  if(this.state.locationlistdata!==undefined){
    var locationlistdata = this.state.locationlistdata;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Location" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Location</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{locationlistdata.location}</p>
                        </div>
                      </div>

                     
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(locationlistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                 

                      </div>
                      </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default (withRouter(View));