import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,productURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

import CKEditor from 'ckeditor4-react';

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const productId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            productId: productId,
			product_name:'',
			price:'',
			quantity:'',
			description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedProductOption:{ value: '2', label: 'Shopping Product'},
			selectedproductvalue:'',
			selectedCategory:'',
			categoryvalue:'',
			categorylist:[],
			image:'',
			image_name: '',
			image_preview: '',
			doctor_visited:false,
			no_of_visite:'',
			expenses:'',
			subscriptionprice:'',
			ebagproduct:false,
		};
		var qs = require('qs');
		var pid = this.state.productId;
		
		axios.get(apiUrl+"product/listdetail?id="+pid).then(res => {
			this.setState({
				image_preview:res.data.productlist.image,
				product_name:res.data.productlist.name,
				price:res.data.productlist.price!=0?`$${res.data.productlist.price}`:'',
				description:res.data.productlist.description,
				expenses:res.data.productlist.expenses,
				subscriptionprice:res.data.productlist.subscriptionprice!=0?`$${Math.round(res.data.productlist.subscriptionprice)
					}`:'',
				selectedproductvalue:res.data.productlist.product_type,
				quantity: res.data.productlist.quantity
			});
			if(res.data.productlist.product_type == 1){
				this.setState({selectedProductOption:{value: '1', label: 'Normal Product'}});
			}else if(res.data.productlist.product_type == 2){
			this.setState({selectedProductOption:{value: '2', label: 'Shopping Product'}});
			}else{
				this.setState({selectedProductOption:{value: '3', label: 'Regular Product'}});

			}
			if(res.data.productlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.selectedcategory!==null && res.data.selectedcategory.length > 0){
				if(res.data.selectedcategory[0].value!== '' && res.data.selectedcategory.value!== null){
					this.setState({ selectedCategory : res.data.selectedcategory[0]})
					// localStorage.setItem("original_selected_category",Props.productdetails[0].selectedcategory[0].value)
					}
			}else{
				this.setState({selectedCategory: '',categoryvalue:''})
			}
			if(res.data.productlist.doctor_visited === 'Yes'){
				this.setState({doctor_visited:true});
				$('.doctor_visite_count').show();
			}else{
				$('.doctor_visite_count').hide();
				this.setState({doctor_visited:false});
			}
			if(res.data.productlist.no_of_visite!==null){
				this.setState({no_of_visite:res.data.productlist.no_of_visite});
			}
			if(res.data.productlist.ebag_product == 'yes'){
				this.setState({ ebagproduct : true});
			}else{
				this.setState({ ebagproduct : false});
			}
		}); 
		
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+localStorage.getItem("admin_id"))
			
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					categorylist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				})	
			}
		});


		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.handleProductChange = this.handleProductChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }

	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
     }
    handleProductChange = selectedProductOption => {
	this.setState({selectedProductOption})
	this.setState({  selectedproductvalue : selectedProductOption.value});
	var productType = selectedProductOption.value;

	if(productType==1){
		$('.pricediv').addClass('hide');
	}else{
		$('.pricediv').removeClass('hide');
	}
	
    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
		const {name, value} = event.target;      
		this.setState({
          [name]: value
        });
		if(name === 'doctor_visited'){
			if(this.state.doctor_visited==false){
				$('.doctor_visite_count').show();
			}
			else{
				$('.doctor_visite_count').hide();
			}
			this.setState({doctor_visited: !this.state.doctor_visited});
  		}	
		if(name ==='no_of_visite'){
			if(value<1){
				this.setState({no_of_visite:''});
			}
	   	} 
		
		if(name == 'ebagproduct'){
			this.setState({ebagproduct: !this.state.ebagproduct})
		}	
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}


	componentDidMount() {
		document.title = PageTitle('product Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var product_type = '';
				var doctor_visited = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.selectedproductvalue === ''){
					product_type =formPayload.selectedProductOption.value;
					}else{
						product_type = formPayload.selectedproductvalue;
					}
				var assigncategory;
				if(formPayload.selectedCategory !== ''){
				assigncategory =formPayload.selectedCategory.value;
				}else{
				assigncategory =formPayload.categoryvalue;
				}

				if(formPayload.doctor_visited === true){
				doctor_visited ='Yes';
				}else{
					doctor_visited = 'No';
				}


				if(formPayload.ebagproduct ==  true)
				{
					var ebagproduct = 'yes';
				}else{
					var ebagproduct = 'no';
				}


				const priceWithoutSymbol = formPayload.price.replace('$', '');
	        	const subpriceWithoutSymbol = formPayload.subscriptionprice.replace('$', '');
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					product_name : formPayload.product_name,
					price: priceWithoutSymbol,
					category_id: assigncategory,			
					description: formPayload.description,
					quantity: formPayload.quantity,
					status:  status,
					id: formPayload.productId,
					image: formPayload.image,
					expenses: formPayload.expenses,
					subscriptionprice:subpriceWithoutSymbol,
					product_type:product_type,
					ebagproduct: ebagproduct,
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"product/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/product'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {product_name,
			price,selectedCategory,
			 quantity,
			expenses,doctor_visited,no_of_visite,selectedproductvalue,subscriptionprice} = this.state;
	
			let errors = {};
			  let formIsValid = true;
	
			if (!product_name) {
				formIsValid = false;
				$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(product_name){
				$('.errorproduct_name').html('');
			}
			if(this.state.ebagproduct ==  false){
				if(!selectedCategory.value){
					formIsValid = false;
					$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
				}else{
					$('.errorcat_name').html('');
				}
			}
			
			if(!selectedproductvalue){
				formIsValid = false;
				$('.errorproductype').html('<span class="errorspan">Please select product type</span>');
			}else{
				
				$('.errorproductype').html('');
			}
			if((selectedproductvalue==2 || selectedproductvalue == 3) && this.state.ebagproduct == false){

				if (!price) {
					formIsValid = false;
					$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
				}else if(price){
					$('.errorprice').html('');
				}
			
				if (!subscriptionprice) {
					formIsValid = false;
					$('.errorsubscriptionprice').html('<span class="errorspan">Please fill the field</span>');
				}else if(subscriptionprice){
					$('.errorsubscriptionprice').html('');
				}
			}
			if (!quantity) {
				formIsValid = false;
				$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
			}else if(quantity){
				$('.errorquantity').html('');
			}

			// if(expenses==true)
			// {
			// 	if (!expenses) {
			// 		formIsValid = false;
			// 		$('.errorexpenses_name').html('<span class="errorspan">Please fill the field</span>');
			// 	}else if(expenses){
			// 		$('.errorexpenses_name').html('');
			// 	}
			// }

		this.setState({
			errors: errors
		});
		return formIsValid;
    }


	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			price: priceWithSymbol
		  });
		}
	  };
	  handlesubPriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			subscriptionprice: priceWithSymbol
		  });
		}
	  };
	  onEditorChange( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt.editor.getData()
        } );
		}, 1000);
     
    }
  render() {
	const categoryArray = this.state.categorylist.map ((categorylist, index) => ({
		id: index,
		name: categorylist,
		}));
		// Dynamically create select list
		let categorys = [];
		categoryArray.map(item =>
		categorys.push({ label: item.name.label, value: item.name.value }),
		);
	const {selectedCategory,selectedOption,selectedProductOption,selectedproductvalue} = this.state;

	let loaderimg = '';
	let preview = '';

	  const imagepre = productURL+'/'+this.state.image_preview;
	
	  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
		  loaderimg = productURL + '/'+this.state.image_preview;
		  preview = <img className="img_class" src={loaderimg} alt="" />;
	  }else{
		  preview = <img className="img_class" src={imagepre} alt="" />;
	  }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Product" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>product Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name}/>
					<div className="errorproduct_name"></div>
				</div>
				<div className="form-group">					
					<label>Ebag product:</label>
						 <input onChange={this.handleInputChange} type="checkbox" name="ebagproduct" checked={this.state.ebagproduct}/>
	           	</div>
				<div className="form-group">
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
					<div className="errorquantity"></div>
				</div>
				{this.state.ebagproduct == false && 
				<div className="form-group">					
					<label>Category: </label>
						<Select 
                         options={categorys}  
                         value={selectedCategory?selectedCategory:{ value: '0', label: 'Select Category' }}
                         onChange={this.handleChangeCategory}
                         placeholder="Select Category" />
					<div className="errorcat_name"></div>
				</div>
  				}
				<div className="form-group">					
					<label>Product Detail:</label>
					
						<CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errordescription"></div>
				</div>
				<div className="form-group">
					<label>Icon:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
				{/* <div className="form-group">
						<input onChange={this.handleInputChange} type="checkbox"  name="doctor_visited"  checked={this.state.doctor_visited}/>
						<span>Doctor Visit</span>
				</div>  */}
				
			
			</div>
                  
			<div className="form-right">
			{/* <div className="form-group">
					<label>Expenses<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="expenses" onChange={this.handleInputChange} className="form-control" value={this.state.expenses}/>
					<div className="errorexpenses_name"></div>
				</div> */}

                      <div className="form-group">					
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="product_type"
							value={selectedProductOption?selectedProductOption:{ value: '2', label: 'Shopping Product'}}
							options={lang.common.productType_option} 
							isSearchable = {false}
							onChange={this.handleProductChange}
						/>
	           	</div> 
				   {selectedproductvalue == 2 || selectedproductvalue == 3 ? <div className="form-group">
					<label>Price($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="price" onChange={this.handlePriceChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>:null}

				{this.state.ebagproduct == false &&  selectedproductvalue == 2 || selectedproductvalue == 3 ? <div className="form-group">
					<label>Subscription Price ($)<span class="required" style={{ color: "red" }} > * </span>: </label>
				    <input type="text" name="subscriptionprice" onChange={this.handlesubPriceChange} className="form-control" value={this.state.subscriptionprice}/>
					<div className="errorsubscriptionprice"></div>
				</div>:null}
				{/* <div className="form-group">					
						<label>Product to Show<span class="required" style={{ color: "red" }} > * </span> :</label>

						<input onChange={this.handleInputChange} type="checkbox" name="productshowuser" checked={this.state.producttoshowuser}/>
						<span>User</span>
						<input onChange={this.handleInputChange} type="checkbox"  name="productshowdoctor" checked={this.state.producttoshowdoctor}/>
						<span>Doctor</span>
	           	</div>  */}
 
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
			   
				{/* <div className="form-group doctor_visite_count">
					<label>No of Visit<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="no_of_visite" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_visite}/>
					<div className="errorno_of_visite"></div>
				</div> */}
             

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}
export default (withRouter(Edit));